import styled from 'styled-components';

export const BackgroundMobile = styled.div`
  width: 100%;
  flex-shrink: 0;
  color: #E9E9F0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-top: 5rem;

`;

export const ImgMobile = styled.div`
 min-width: 320px;
  flex-shrink: 0;

  img{
    width: 100%;
    height: 100%;
  }
`

export const SectionMobile = styled.div`
  display: flex;
    text-align: end;

`;


