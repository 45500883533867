import styled from 'styled-components'
import {Link} from 'react-router-dom';
import BREAKPOINTS from "../../../styles/deviceBreakpoints";

export const NavbarMobile = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 7rem;
  flex-shrink: 0;
  background: #151515;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.39);
  z-index: 1000;
  a {
    text-decoration: none;
 color: #FFC000;
  }
`


export const LogoMobile = styled.img`
  width: 153px;
  height: 16px;
  flex-shrink: 0;
  display: block;
  margin-left: 1rem;
  margin-top: 1.1875rem;
  margin-bottom: 1.136rem;


`
export const MenuBarsMobile = styled.div`
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 3rem;
z-index: 997;
`

export const ContentBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.71);

`

export const NavMobileContent = styled.div`
  width: 60%;
  padding: 1rem;
  height: 100vh;
  flex-shrink: 0;
  right: 0;
  top: 7rem;
  position: fixed;
  z-index: 99999;
  background: #151515;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

`

export const LinkStyled = styled.div`
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

`

export const NavBarMobile = styled.nav`

  display: flex;
  width: 60%;
  height:5.5rem;
  flex-direction: column;
  justify-content: center;
  align-content: end;
  flex-shrink: 0;

`


export const NavUlMobile = styled.ul`
  list-style: none;
  margin-top: 50%;
  justify-content: center;

  .yellow {
    color: #FFC000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;


    &:hover {
      color: #FFC000;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &:hover::after,
    &:focus::after,
    &:active::after {
      color: #FFC000;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }


    &::after {
      content: '';
      position: absolute;
      width: 80%;
      height: 0.2rem;
      background: #FFC000;
      bottom: -0.5rem;

    }

    &:hover::after {
      content: '';
      position: absolute;
      width: 80%;
      height: 0.2rem;
      background: #FFC000;
      bottom: -0.5rem;

    }

  }

`

export const NavLiMobile = styled.li`
  display: flex;
  width: 14.75rem;
  height: 5.5rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  position: relative;



  &:hover {
    color: #FFC000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &:hover::after,
  &:focus::after,
  &:active::after {
    color: #FFC000;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }


  &::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 0.02rem;
    background: #E3E3E3;
    bottom: -0.5rem;

  }

  &:hover::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 0.2rem;
    background: #FFC000;
    bottom: -0.5rem;

  }



`

export const ButtonMobile = styled.button`
  background: #ffc000;
  color: black;
  text-decoration: none;
  border-style: none;
  font-family: 'Roboto';
  font-size:16px;
  font-weight: 800;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  text-align: center;
  width: 15.75rem;
  height: 3.75rem;
  flex-shrink: 0;
  margin-top: 2rem;
  a {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-decoration: none;
    color: black;
  }

`
export const ButtonContainer = styled.div`
  height: 3.75rem;
  width: 60%;
`

export const Social = styled.div`
  display: flex;
  margin-bottom: 2rem;
`

export const NavSocialLista = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: row;
`
export const NavSocialItem = styled.div`
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  img{
    width: 15px;
    height:14px;
    flex-shrink: 0;

  }
`


