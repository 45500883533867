import styled, { css } from 'styled-components'

export const WrapperText = styled.div`
  width: 100%;
  margin-left: 0;
`

export const TabText = styled.p<{ withTitle?: boolean; activeTab?: boolean }>`
  ${({ activeTab }) => css`
    display: block;
    white-space: normal;
    color: #e9e9f0;
    font-size: 1rem;
    font-weight: ${activeTab ? '700' : '400'};
    flex: 1;
    opacity: ${activeTab ? '1' : '0,98'};
    text-align: center;
    padding: 0;

  `}
    &.ant-tabs .ant-tabs-tab:hover {
      color: #ff0000;
    }

  width: 170px;
  text-align: center;

`

export const ContentTab = styled.div`
  flex: 1;
  color: #e9e9f0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-left: 0;
`
