import React, { useEffect, useRef } from 'react';
import * as S from './styles'

interface TermsModalProps {
  onClose: () => void;
}

export const TermsModal: React.FC<TermsModalProps> = ({ onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <S.Container>

    <div className="terms-modal">
      <div className="terms-content" ref={modalRef}>
        <div className="max">


        <h1>Política de Cookies</h1>
<br/>
          <p>Cookies são pequenos arquivos de texto gerados pelo site da Candidattus® e transferidos para o seu
            computador, onde são armazenados pelos navegadores da web. Eles são principalmente utilizados para otimizar
            sua experiência de navegação em nosso site, desde que permitido pelas configurações de segurança do seu
            navegador. Quando o seu navegador está configurado para permitir, nós utilizaremos cookies conforme
            explicado anteriormente.
            <br />
            <br />
            Os cookies utilizados pelo site da Candidattus® têm o propósito de identificar tendências de navegação dos
            usuários, visando aprimorar e personalizar suas futuras interações, como páginas visitadas e links clicados.
            É importante destacar que esses cookies não são usados para executar programas ou infectar o seu computador
            com vírus ou programas maliciosos.
            <br />
            <br />
            Ao acessar nosso site pela primeira vez, você foi informado por meio de um aviso de texto sobre a prática de
            cookies do site da Candidattus®. Mesmo que seu navegador esteja configurado para aceitar cookies, se você
            desejar remover os cookies existentes e impedir o armazenamento de novos, você pode fazê-lo facilmente,
            desativando esse recurso no seu navegador e excluindo os cookies já armazenados.
            <br />
            <br />
            Por isso, recomendamos que você ajuste as configurações de segurança do seu navegador antes de navegar em
            nosso site, em todos os computadores e dispositivos que você utilizar. </p>
        </div>
        <button  type='button' onClick={onClose}>Fechar</button>
      </div>
    </div>
    </S.Container>
  );
};


