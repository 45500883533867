import React, { useState } from 'react'
import EmailLink from 'components/EmailTo'
import {Link } from 'react-router-dom';
import { Top } from '../BackTop'
import { BuildModal } from "../ModalBuild";
import { BuildModalMobile } from "../ModalBuild/indexMobile";
import logoHeader from '../../assets/images/logo_header.png'
import FacebookIcon from '../../assets/images/facebook-icon.svg'
import LinkedinIcon from '../../assets/images/linkedin-icon.svg'
import InstagramIcon from '../../assets/images/instagram-icon.svg'
import TwitterIcon from '../../assets/images/x.svg'
import { TermsModalMobile } from '../Modal/indexMobile'
import * as S from './stylesMobile'

const FooterMobile = () => {
  const [isTermsModalOpen, setTermsModalOpen] = useState(false);

  const handleOpenTermsModal = () => {
    setTermsModalOpen(true)
  }

  const handleCloseTermsModal = () => {
    setTermsModalOpen(false)
  }

  return (
    <S.Container>
      <S.Footer>
        <S.Top>

        <Top />
        </S.Top>
        <S.LogoFooter src={logoHeader} />

        <S.Content>
          <S.Social>
            Siga-nos:
            <S.NavSocialLista>
              <S.NavSocialItem> <a
                href="https://www.facebook.com/candidattus"
                aria-label="insta-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={FacebookIcon} alt="IG icon" />
              </a></S.NavSocialItem>
              <S.NavSocialItem> <a
                href="https://www.linkedin.com/company/candidattus/"
                aria-label="insta-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedinIcon} alt="IG icon" />
              </a></S.NavSocialItem>
              <S.NavSocialItem> <a
                href="https://www.instagram.com/candidattus/"
                aria-label="insta-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={InstagramIcon} alt="IG icon" />
              </a></S.NavSocialItem>
              <S.NavSocialItem> <a
                href="https://twitter.com/candidattus"
                aria-label="insta-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={TwitterIcon} alt="IG icon" />
              </a></S.NavSocialItem>
            </S.NavSocialLista>

          </S.Social>
          <hr />
        </S.Content>
        <S.Contact>
          <S.ContactLista>Contato:</S.ContactLista>
          <S.ContactItem>+55 (11) 3254-7507</S.ContactItem>
        </S.Contact>
        <S.Contact>
          <S.ContactLista>Email:</S.ContactLista>
          <S.ContactItem><EmailLink email="contato@candidattus.com"  /></S.ContactItem>
        </S.Contact>
        <hr />
        <S.NavMenuLinks>
          <ul>
            <li><a href="sem-link">Plataforma</a></li>
            <li><a href="sem-link">Soluções</a></li>
            <li><a href="sem-link">Planos</a></li>
            <li><a href="sem-link">Blog</a></li>
            <li><a href="sem-link">Parcerias</a></li>
            <li><a href="sem-link">Sobre nós</a></li>
          </ul>
        </S.NavMenuLinks>
        <hr />
        <S.Copyright>
          Tecnologia Algoricca© Software Company. ©Candidattus 2024. Todos os direitos reservados.
          <br />Confira a nossa
          <button type="button" onClick={handleOpenTermsModal} className="terms-link">
            Política de Cookies{" "}
          </button>
          .{isTermsModalOpen && <TermsModalMobile onClose={handleCloseTermsModal} />}
        </S.Copyright>

      </S.Footer>

    </S.Container>
  )
}

export default FooterMobile
