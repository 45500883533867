import styled from 'styled-components'
import BREAKPOINTS from '../../styles/deviceBreakpoints'

export const Footer = styled.div`
  /* box-shadow: 0px -0.625rem 0.9375rem -0.625rem rgba(0, 0, 0, 0.75); */
  background: #0A0A0A;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 1);
  color: #e9e9f0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 0.06rem;
  /* height: 19.5rem; */
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;

  hr {
    align-self: center;
    width: 900px;
    border-color: rgba(233, 233, 240, 0.67);
    margin: 30px;
  }

  li {
    list-style-type: none;
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

`



export const LogoFooter = styled.img`
  width: 17.49875rem;
  height: 1.875rem;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;

`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.9fr 0.5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1336px;
`

export const Links = styled.div`
  grid-column: 1;
  line-height: 1.125rem;
  align-self: center;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0em;
  li {
    padding-top: 0.2rem;
  }

`

export const Contatos = styled.div`
  grid-column: 2;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  justify-self: end;
  align-self: center;

  li {
    margin-top: 1rem;
  }

  grid-column-gap: 1rem;

`

export const Social = styled.div`
  display: flex;
  margin-top: 1.2rem;
  text-align: left;
  align-self: center;
  a {
    padding-right: 1rem;
  }
  img {
    width: 1rem;
    height: 1rem;
  }
`

export const Copyright = styled.div`
  font-family: Roboto;
  font-size: 0.69rem;
  font-weight: 400;
  line-height: 0.875rem;
  letter-spacing: 0em;
  text-align: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0.3125rem;
  color: #e9e9f0;
  width: 17rem;

  .terms-link {
    font-size: 0.69rem;
    color: white;
    background: transparent;
    text-decoration: underline;
    border-style: none;
    text-underline: white;
    cursor: pointer;
    margin-left: 0.2rem;
  }
`

export const Text = styled.p`
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
  letter-spacing: 0em;

`
export const Details = styled.div`
  grid-column: 3;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  text-align: left;
  align-self: center;
  li {
    margin-top: 1rem;
  }

  .icon-social-media {
  }

`
