export const SLIDE_DATA_MOBILE = [
  {
    title: 'O que é Inteligência Eleitoral?',
    text: `Inteligência Eleitoral é a habilidade de explorar uma variedade de fontes de dados e combinar metodologias analíticas para obter conhecimentos sociais e eleitorais de uma localidade. Essa prática visa enriquecer as bases de conhecimento que servem como matéria-prima para a elaboração de estratégias de campanha política.`,
  },
  {
    title: 'O que fazemos?',
    text: `As eleições geram muitos dados valiosos. A Candidattus® é uma plataforma que coleta, seleciona e gera insights políticos usando inteligência artificial. Nossa tecnologia analisa dados georreferenciados de comportamentos, padrões, interesses e tendências, ajudando os tomadores de decisão a desenvolverem estratégias adaptadas ao eleitorado.`,
  },
  {
    title: 'Segurança, estabilidade e alta performance',
    text: `Na Candidattus®, implementamos mecanismos robustos de segurança para garantir a integridade e confidencialidade dos dados. A plataforma está totalmente alinhada com a Lei Geral de Proteção de Dados Pessoais (LGPD) e outras regulamentações aplicáveis, assegurando a legalidade no tratamento dessas informações.`,
  },
]
