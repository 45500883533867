import styled, { css } from 'styled-components'
import BREAKPOINTS from '../../styles/deviceBreakpoints'

export const NavbarContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.75rem;
  background: rgba(10, 10, 10, 0.95);
  box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.7);
  z-index: 999;

  @media (max-width: ${BREAKPOINTS.WIDHT.LG}) {
    margin-left: 170px;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1336px;
`

export const Logo = styled.img`
  width: 13.625rem;
  height: 1.429rem;
  display: block;
  margin-left: 2.5rem;
  margin-top: 1.1875rem;
  margin-bottom: 1.136rem;
`

export const NavbarLink = styled.span<{ active?: boolean }>`
  ${({ active }) => css`
    font-family: Roboto;
    font-size: 0.875rem;
    width: 6.25rem;
    font-weight: 700;
    line-height: 1.1875rem;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    display: inline-block;

    cursor: pointer;
    align-items: center;
    justify-content: space-around;
    margin-right: 2rem;
    padding-bottom: 1.275rem;
    text-decoration: none;

    a {
      color: ${active ? '#ffc000' : 'white'};
    }

    ${active &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.25rem;
        background: #ffc000;
      }
    `}
  `}
`
export const NavbarLinkNot = styled.a`
  width: 89px;
  height: 57px;
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 900;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin-right: 3rem;
`

export const Button = styled.button`
  background: #ffc000;
  color: black;
  text-decoration: none;
  border-style: none;
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  text-align: center;
  width: 13.75rem;
  height: 3.75rem;

  a {
    text-decoration: none;
    color: black;
  }
`
