import styled from 'styled-components'

export const Container = styled.div`
  margin: 70px 0;
  width: 100%;
  color: black;
  display: flex;
  align-content: center;
`
export const ContainerMobile = styled.div`
  margin-top: 70px;
  width: 100%;
  height: 456px;
  background-color: yellow;
  color: black;
  display: flex;
  align-content: center;
`
