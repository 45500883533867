import { AcquisitionTable } from './AcquisitionComponents/AcquisitionTable'
import * as S from './styles'

export const DesktopTemplate = () => {
  return (
    <S.Container>
      <AcquisitionTable />
    </S.Container>
  )
}
