import styled from "styled-components";
import { Card } from 'antd';

export const Container = styled(Card)`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  flex-shrink: 0;
  border-radius: 8px;
  background: #111;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  border-style: none;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  position: relative;
color: white;

`

export const Content= styled.div`
  width: 100%;

  color: #E9E9F0;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px; /* 100% */
  margin-top: 1rem;
`
export const Title= styled.div`
  margin-top: 1rem;
  color: #D9D9D9;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 100% */
`
export const Text= styled.div`
  margin-top: 1rem;
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px; /* 100% */
`

export const SobConsulta= styled.div`
  margin-top: 2rem;
  color: #E9E9F0;
  text-align: center;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px; /* 75% */

`
export const Button= styled.button`
  a{
    text-decoration: none;
    color: black;
  }
  margin-top: 1rem;

  width: 100%;
  height: 58px;


  gap: 10px;
  flex-shrink: 0;
  background: #FFC000;
  border-style: none;

  color: #161717;

  /* M3/body/large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
`
export const ContentHub = styled.div`
width: 130%;
  background-color: black;
`

export const Ver = styled.div`

  color: #FFC000;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 75% */
  text-decoration-line: underline;
  margin-top: 15px;
  margin-bottom: 15px;
`
export const Setas = styled.div`
    margin-top: 0.5rem;
  color: #FFC000;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  stroke-width: 2px;
  line-height: 12px; /* 75% */
  text-decoration-line: underline;
`
