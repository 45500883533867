export const PLAN_TYPES = [
  {
    type: 'silver',
    title: 'Prata',
    description:
      'Otimize sua estratégia digital com a integração de ferramentas de análise e gestão simplificada; plano construído para autogestão ou gestão de primeira campanha.',
  },
  {
    type: 'gold',
    title: 'Ouro',
    bestPlan: true,
    description:
      'Envie mensagens personalizadas no momento certo, considerando interesses políticos e comportamento online do eleitor-alvo; plano construído para campanhas mais arrojadas, focada em grandes resultados.',
  },
  {
    type: 'platinium',
    title: 'Platina',
    description:
      'Direcione sua campanha com maior número de dados, ferramentas de otimização e automações aprimoradas; plano construído para partidos de pequeno e médio portes.',
  },
  {
    type: 'diamond',
    title: 'Diamante',
    description:
      'Desbloqueie todos os recursos da plataforma, desde ferramentas estratégicas e de planejamento até espaços otimizados para a gestão de candidaturas; plano construído para partidos de grande porte.',
  },
]

export const PACKAGES_BY_PLAN = [
  {
    package_type: 'Fidelidade',
    define_package_type: true,
    options: {
      silver: '6 meses',
      gold: '12 meses',
      platinium: '12 meses',
      diamond: '12 meses',
    },
  },
  {
    package_type: 'Assinaturas mensais',
    options: {
      silver: '1 Assinatura',
      gold: 'Min. 1 Assinatura',
      platinium: '50 Assinaturas',
      diamond: 'Min. 200 Assinaturas',
    },
  },
  {
    package_type: 'Valor mensal',
    options: {
      silver: 'Sob consulta',
      gold: 'Sob consulta',
      platinium: 'Sob consulta',
      diamond: 'Sob consulta',
    },
  },
  {
    package_type: 'Cockpit de candidatura',
    sub_package: [
      {
        package_type: 'Cockpit',
        options: {
          silver: true,
          gold: true,
          platinium: true,
          diamond: true,
        },
      },
    ],
  },
  {
    package_type: 'Estratégia de Candidatura',
    sub_package: [
      {
        package_type: 'Análise Estratégica',
        options: {
          silver: true,
          gold: true,
          platinium: true,
          diamond: true,
        },
      },
      {
        package_type: 'Metas de Candidatura',
        options: {
          silver: true,
          gold: true,
          platinium: true,
          diamond: true,
        },
      },
      {
        package_type: 'Metas Por Zona Eleitoral',
        options: {
          silver: false,
          gold: false,
          platinium: false,
          diamond: true,
        },
      },
      {
        package_type: 'Simulação de Custos',
        options: {
          silver: false,
          gold: false,
          platinium: false,
          diamond: true,
        },
      },
    ],
  },

  {
    package_type: 'Gestão da Candidatura',
    sub_package: [
      {
        package_type: 'Planejamento',
        options: {
          silver: false,
          gold: false,
          platinium: true,
          diamond: true,
        },
      },
      {
        package_type: 'Programação',
        options: {
          silver: false,
          gold: false,
          platinium: true,
          diamond: true,
        },
      },
      {
        package_type: 'Equipe',
        options: {
          silver: false,
          gold: '1 Pessoa',
          platinium: 'até 5 pessoas',
          diamond: 'até 10 pessoas',
        },
      },
    ],
  },

  {
    package_type: 'Inteligência Política',
    sub_package: [
      {
        package_type: 'Diagnóstico Político',
        options: {
          silver: true,
          gold: true,
          platinium: true,
          diamond: true,
        },
      },
      {
        package_type: 'Politica Vox® Candidatura',
        options: {
          silver: true,
          gold: true,
          platinium: true,
          diamond: true,
        },
      },
      {
        package_type: 'Candidato Preferido',
        options: {
          silver: false,
          gold: true,
          platinium: true,
          diamond: true,
        },
      },
      {
        package_type: 'Arquétipo Político®',
        options: {
          silver: false,
          gold: true,
          platinium: true,
          diamond: true,
        },
      },
    ],
  },

  {
    package_type: 'Trajetória Política',
    sub_package: [
      {
        package_type: 'Estratégia de Carreira',
        options: {
          silver: false,
          gold: true,
          platinium: true,
          diamond: true,
        },
      },
      {
        package_type: 'Perfil Político',
        options: {
          silver: true,
          gold: true,
          platinium: true,
          diamond: true,
        },
      },
      {
        package_type: 'SkillMatch Pessoal',
        options: {
          silver: false,
          gold: false,
          platinium: true,
          diamond: true,
        },
      },
      {
        package_type: 'Plano de Carreira',
        options: {
          silver: false,
          gold: false,
          platinium: true,
          diamond: true,
        },
      },
    ],
  },

  {
    package_type: 'Portfólio de Candidaturas',
    sub_package: [
      {
        package_type: 'Candidaturas Patrocinadas',
        options: {
          silver: false,
          gold: false,
          platinium: true,
          diamond: true,
        },
      },
      {
        package_type: 'Todas as Candidaturas',
        options: {
          silver: false,
          gold: false,
          platinium: true,
          diamond: true,
        },
      },
      {
        package_type: 'Politica Vox® Partido',
        options: {
          silver: false,
          gold: false,
          platinium: true,
          diamond: true,
        },
      },
    ],
  },
]
