import React, { useEffect, useRef } from 'react';
import * as S from './stylesMobile'

interface BuildModalMobileProps {
  onClose: () => void;
}

export const BuildModalMobile: React.FC<BuildModalMobileProps> = ({ onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <S.Container>
      <S.Content ref={modalRef}>
        <div className="max">
        <S.Heading>Site em Construção</S.Heading>
          <S.Text>Pedimos desculpas pelo inconveniente. Esta página ainda está em desenvolvimento, mas estamos empenhados em conclui-la o mais rápido possível. Agradecemos a compreensão!</S.Text>
        </div>
        <S.Button  type='button' onClick={onClose}>Fechar</S.Button>
    </S.Content>

    </S.Container>
  );
};


