import styled from 'styled-components'
import BREAKPOINTS from "../../styles/deviceBreakpoints";

export const Background = styled.div`
  width: 100%;
  background: #0a0a0a;

`

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 350px;
`

export const ImageBox = styled.div`
    width:578px;
`
export const Image = styled.img`

  width:578px;
  height: 363px;
  margin-top: 1rem;
  margin-left: 3rem;
  @media (max-width: ${BREAKPOINTS.WIDHT.LG}) {
    margin-left: 80px;
  }
`

export const ContentSlide = styled.div`
  width:50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-right: 144px;
`

export const ContentText = styled.div`
  text-align: center;
`

export const Heading = styled.h3`
  font-size: 32px;
  font-weight: 700;
  color: #d9d9d9;
  margin-bottom: 70px;
  font-family: 'Roboto', sans-serif;
`

export const Text = styled.p`
  margin-bottom: 80px;
  color: #D9D9D9;
  text-align: center;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 550px;
  margin-left: 3rem;
`
