import React from 'react'
import { Card } from 'antd'
import * as S from './styles'
import Cockpit from '../../assets/banners-media/Cockpit.svg'
import Strategy from '../../assets/banners-media/Estratégia Candidatura.svg'
import CandManage from '../../assets/banners-media/Gestão da Candidatura.svg'
import PortManage from '../../assets/banners-media/Gestão de portifolio.svg'
import IntelligencePolitycal from '../../assets/banners-media/Inteligência Política.svg'
import TragetoryPolytical from '../../assets/banners-media/Trajetória Política.svg'
import { CardHeaderLast } from "./styles";

const Icons = [Cockpit, Strategy, CandManage, IntelligencePolitycal, TragetoryPolytical, PortManage]

const Titles = [
  'Cockpit do Candidato',
  'Estratégia de Candidatura',
  'Gestão da Candidatura',
  'Inteligência Política',
  'Trajetória Política',
  'Portifólio de Candidaturas',
]

const Contents = [
  'Funciona como o painel de controle do candidato, onde as principais ferramentas são apresentadas de forma intuitiva. Em um só lugar, é possível acessar e gerenciar informações relevantes para a campanha eleitoral, como dados pessoais e de candidatura, contagem de dias para as eleições, metas, menções sociais e comparativo dos candidatos.',
  'Neste ambiente, o usuário terá acesso a ferramentas de planejamento e estratégia que incluem funcionalidades como Análise Estratégica, Escopo de Candidatura, Metas por Zona Eleitoral e Simulação de Custos para cada cenário.',
  'Espaço que permite a administração completa da campanha eleitoral, desde o planejamento inicial até a coordenação da agenda e programação de compromissos, além do controle e atribuições dos usuários na conta, tudo de maneira automatizada.',
  'Nossa plataforma integra funcionalidades analíticas e preditivas avançadas que oferecem o diagnóstico preciso da sua campanha eleitoral. Isso inclui o monitoramento contínuo da opinião pública através da Inteligência de Mídias Sociais®, a identificação do candidato preferido dos eleitores e do perfil do candidato ideal com base no Arquétipo Político®.',
  'Esta aplicação centraliza uma variedade de informações pessoais, profissionais e políticas, as quais são organizadas em quatro funcionalidades principais: Estratégia de Carreira, Perfil Político, SkillMatch Pessoal e Plano de Carreira.',
  'Ambiente exclusivo para agências, partidos políticos e doadores. Nesse espaço, os usuários podem visualizar detalhes sobre candidaturas patrocinadas ou gerais, organizadas nos Portfólios de Coligações.',
]


const Cards = () => (
  <S.Main >
    {Icons.map((icon, index) => (
      <S.Container key={index} >
        {index === Icons.length - 1 && (
          <S.CardHeaderLast>
            <S.CardHeaderTextLast>
              Para Agências, Partidos e Doadores.
            </S.CardHeaderTextLast>
          </S.CardHeaderLast>
        )}
        <S.Content style={{marginLeft: index === Icons.length - 1 ? '10px' : '0',marginRight: index === Icons.length - 1 ? '10px' : '0'}}>
        <S.Icon>
          <img src={icon} alt={Titles[index]} />
        </S.Icon>
        <S.Title>{Titles[index]}</S.Title>
        <S.Text>{Contents[index]}</S.Text>
        </S.Content>
      </S.Container>
    ))}
  </S.Main>
)

export default Cards
