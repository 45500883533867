import styled from 'styled-components';

export const Container = styled.div`

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.26);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
`;

export const ContentBox = styled.div`
display: flex;
  justify-content: center;
 width: 100%;
`;

export const Content = styled.div`
  width: 328px;
  height: 262px;
  margin-bottom: 2rem;
  position: fixed;
  bottom: 0;

  flex-shrink: 0;
  border-radius: 16px;
  background: #161717;
  box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.75);

  border-style: none;
  text-underline: white;
  text-align: center;

  padding: 2rem 2rem 2rem 2rem;
  img {
    width: 42px;
    height: 38px;
    flex-shrink: 0;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;

  }
`;

export const CookieContent = styled.div`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  width: 296px;
  text-align: start;
  justify-content: center;
  align-items: center;


  margin-left: auto;
  .terms-link {
    font-size: 1.02em;
    color: white;
    background: transparent;
    font-weight: 700;
    text-decoration: underline;
    border-style: none;
    text-underline: white;
    cursor: pointer;
    margin-left: 0.3rem;
  }

`;

export const CookieButton = styled.button`
  display: flex;
  width: 212px;
  height: 54px;
  padding: 16px;
  justify-content: center;
  align-items: end;
  flex-shrink: 0;
  border-radius: 8px;
  background: #FFC000;
  margin-top: 1rem;
  border-style: none;
  color: #161717;
    margin-left: calc(10%);
  /* M3/body/large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
`;
