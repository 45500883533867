import React from 'react';
import { useMediaQuery } from "react-responsive";
import Slider from 'react-slick';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import * as S from "./styles";
import * as SM from "./stylesMobile";
import gifs from "./assets/images/bg.png";
import vencer from "./assets/images-slider/slide-1.png";
import conecta from "./assets/images-slider/slide-2.png";
import vantagem from "./assets/images-slider/slide-3.png";
import impulsona from "./assets/images-slider/slide-4.png";
import vencerMobile from "./assets/images-slider/slidem-1.png";
import conectaMobile from "./assets/images-slider/slidem-2.png";
import vantagemMobile from "./assets/images-slider/slidem-3.png";
import impulsonaMobile from "./assets/images-slider/slidem-4.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css-slider/dots.css";

const Banner = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 769px)' });

  return (
    <>
      {isMobile ? <Mobile /> : <Desktop />}

    </>
  )
}

const Mobile = () => {

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 10000,
    fade: true,
    nextArrow: <SlArrowRight color="transparent" />,
    prevArrow: <SlArrowLeft color="transparent" />,
  };

  return (
    <SM.BackgroundMobile  style={{ width: `100%`}}>
      <Slider {...settings}>
          <SM.SectionMobile>
            <SM.ImgMobile>
              <img src={vencerMobile} alt='vencer'/>
            </SM.ImgMobile>
          </SM.SectionMobile>
          <SM.SectionMobile>
            <SM.ImgMobile>
              <img src={conectaMobile} alt='lolo'/>
            </SM.ImgMobile>
          </SM.SectionMobile>
        <SM.ImgMobile>
          <img src={vantagemMobile} alt='lolo'/>
        </SM.ImgMobile>
        <SM.ImgMobile>
          <img src={impulsonaMobile} alt='lolo'/>
        </SM.ImgMobile>
      </Slider>
    </SM.BackgroundMobile>
  );
};

const Desktop = () => {

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 10000,
    fade: true,
  };

  return (
    <S.Background style={{ backgroundImage: `url(${gifs})` , width: `100%`}}>

      <Slider {...settings}>
          <S.Section>
            <S.Img src={vencer} alt="O que é " />
          </S.Section>
          <S.Section>
            <S.Img src={conecta} alt="O que é " />
          </S.Section>
          <S.Section>
            <S.Img src={vantagem} alt="O que é " />
          </S.Section>
          <S.Section>
            <S.Img src={impulsona} alt="O que é " />
          </S.Section>
      </Slider>
    </S.Background>
  );

};

export default Banner;
