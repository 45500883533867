const BREAKPOINTS = {
  WIDHT: {
    XS: '320px',
    SM: '435px',
    MD: '768px',
    LG: '1024px',
    XL: '1920px'
  },
  HEIGHT: {
    EXTRASMALL: '568px',
    SMALL: '600px',
    MEDIUM: '800px',
    HIGHT: '1000px',
    EXTRAHIGHT: '1200px'
  }
}

export default BREAKPOINTS;
