import React from 'react'
import Slider from 'react-slick'
import { SlArrowRight, SlArrowLeft } from 'react-icons/sl'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as S from './stylesMobile'

export interface CustomSliderMobileProps {
  children: React.ReactNode
  arrowPosition?: 'center' | 'start' | 'end'
  countSlide?: number
}

export const CustomSliderMobile = ({ children, arrowPosition = 'end', countSlide }: CustomSliderMobileProps) => {
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 600,
    autoplaySpeed: 10000,
    fade: true,
    nextArrow: <SlArrowRight color="transparent" />,
    prevArrow: <SlArrowLeft color="transparent" />,
  }

  return (
    <S.Wrapper arrowPosition={arrowPosition} countSlide={countSlide}>
      <Slider {...settings}>{children}</Slider>
    </S.Wrapper>
  )
}
