export const SLIDE_DATA = [
  {
    title: 'O que é Inteligência Eleitoral?',
    text: `Inteligência Eleitoral é a habilidade de explorar uma variedade de fontes de dados e combinar metodologias analíticas para obter conhecimentos sociais e eleitorais de uma localidade. Essa prática visa enriquecer as bases de conhecimento que servem como matéria-prima para a elaboração de estratégias de campanha política.`,
  },
  {
    title: 'O que fazemos?',
    text: `As eleições geram enormes quantidades de dados contendo informações valiosas. Nesse contexto, surgiu a Candidattus® Inteligência Eleitoral, plataforma dedicada à coleta de dados, curadoria política e geração de insights por inteligência artificial. Nossa tecnologia coleta, organiza e cruza dados georreferenciados de comportamentos, padrões, interesses e tendências para ajudar os tomadores de decisão a desenvolverem estratégias mais precisas e adaptadas às nuances específicas do eleitorado.`,
  },
  {
    title: 'Segurança, estabilidade e alta performance',
    text: `Na Candidattus®, implementamos mecanismos robustos de segurança para garantir a integridade e confidencialidade dos dados. A plataforma está totalmente alinhada com a Lei Geral de Proteção de Dados Pessoais (LGPD) e outras regulamentações aplicáveis, assegurando a legalidade no tratamento dessas informações. Nossa política rigorosa de cybersecurity garante que as informações não sejam acessadas ou divulgadas a indivíduos, entidades ou aplicativos não autorizados.`,
  },
]
