import React from 'react'
import PropTypes from 'prop-types'
import * as S from './style'

interface paramsMailTypes {
  message: string
  cc?: string
  subject?: string
  bcc?: string
}

interface EmailLinkProps {
  email: string
}

export function buildQueryString(params: Record<string, any>): string {
  const queryString = Object.entries(params)
    .filter(([key, value]) => !!value)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')

  return queryString ? `?${queryString}` : ''
}

const EmailLink: React.FC<EmailLinkProps> = ({ email }: EmailLinkProps) => {

  const emailLink = `mailto:${email}`

  return (
    <S.Link>
    <a href={emailLink} target="_blank" rel="noreferrer">
     {email}
    </a>
    </S.Link>
  )
}

export default EmailLink
