import styled from 'styled-components'

export const Zap = styled.div`
  .whatsapp-link {
    position: fixed;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    bottom: 40px;
    right: calc(10%);
    cursor: pointer;
    text-align: center;
    z-index: 998;
    transform: scale(0.8);
    transform-origin: 0 0;
  }

`;

