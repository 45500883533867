import styled from 'styled-components'

export const Footer = styled.div`
  /* box-shadow: 0px -0.625rem 0.9375rem -0.625rem rgba(0, 0, 0, 0.75); */
  background: #0A0A0A;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 1);
  color: #e9e9f0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 0.06rem;
  /* height: 19.5rem; */
  /* position: absolute; */
  bottom: 0;
  /* left: 0; */
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;

  hr {
    align-self: center;
    width: 90%;
    border-color: rgba(233, 233, 240, 0.67);
    margin: 20px;
  }

  li {
    list-style-type: none;
  }
`

export const Container = styled.div`
  width: 100%;

  flex-shrink: 0;
`

export const LogoFooter = styled.img`
  width: 279.96px;
  height: 30px;
  flex-shrink: 0;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;

`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-content: center;

`

export const Links = styled.div`
  grid-column: 1;
  line-height: 1.125rem;
  align-self: center;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0em;
  li {
    padding-top: 0.2rem;
  }

`

export const Copyright = styled.div`
  color: #E9E9F0;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:30px;
  padding: 0.3125rem;
  color: #e9e9f0;
  width: 278px;


  .terms-link {
    color: #E9E9F0;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: white;
    background: transparent;
    text-decoration: underline;
    border-style: none;
    text-underline: white;
    cursor: pointer;
    margin-left: 0.2rem;
  }
`

export const Text = styled.p`
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
  letter-spacing: 0em;

`
export const NavLi = styled.li`

  a{
    color: #E9E9F0;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    list-style: none;
    text-decoration: none;

  }

`

export const Social = styled.div`
  display: flex;
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
`

export const NavMenuLinks = styled.div`
  margin-left: 3rem;
  a{
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  list-style: none;
  text-decoration: none;

  }
`

export const NavSocialLista = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: row;
`

export const NavSocialItem = styled.div`
  margin-right: 1rem;
  margin-left: 0.5rem;
  img{
    width: 20px;
    height: 20px;
    flex-shrink: 0;

  }
`



export const ContactLista = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 3rem;
`
export const Contact = styled.div`
  display: flex;
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: start;
  align-self: start;
  margin-top: 1rem;

`
export const ContactItem = styled.div`
  margin-left: 2rem;

`

export const Top = styled.div`
margin-left: calc(5%);
`

