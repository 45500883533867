import { keyframes, styled } from 'styled-components'

const animte = keyframes`
50% {
    opacity: 0.5;
}
100%, 0% {
    opacity: 1;
}
`

export const Wrapper = styled.main<{ position?: number; best?: boolean }>`
  border: 2px solid #393939;
  transition: 0.5s;
  position: absolute;
  top: -228px;
  height: 133%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  max-width: 180px;
  padding: 0 8px;
  padding-top: 28px;
  padding-bottom: 14px;

  border-color: ${props => (props.best ? '#ffc000' : '#393939')};

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    max-width: 17%;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Badge = styled.span`
  animation: ${animte} 3s linear infinite;
  color: #ffc000;
  font-weight: 900;
  font-size: 10px;
  margin-top: -20px;
  text-align: right;
  text-transform: uppercase;
`

export const TitleCard = styled.h3`
  font-size: 24px;
  font-weight: 500;
  color: #d9d9d9;
`

export const Description = styled.p`
  color: #e9e9f0;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
`

export const Button = styled.button`
  a {
    text-decoration: none;
    color: black;
  }
  margin-top: 1rem;

  width: 100%;
  height: 58px;

  gap: 10px;
  flex-shrink: 0;
  background: #ffc000;
  border-style: none;
  border-radius: 8px;

  color: #161717;

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`
