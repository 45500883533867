import React from "react";
import { useMediaQuery } from "react-responsive";
import Banner from '../../components/Banner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import FooterMobile from '../../components/Footer/indexMobile'
import Whatsapp from '../../components/Whatsapp'
import WhatsappMobile from '../../components/Whatsapp/mobile/indexMobile'
import Cookies from '../../components/Cookies'
import CookiesMobile from '../../components/Cookies/indexMobile'
import BannerIntelligence from '../../components/Banner2'
import * as S from './styles'
import BannerTabs from '../../components/BannerTabs/index'
import BannerTabsMobile from '../../components/BannerTabs/indexMobile'
import BannerModules from '../../components/BannerModules'



const Main = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 769px)' });

  return (
    <>
      {isMobile ? <Mobile /> : <Desktop />}

    </>
  )
}

const Mobile = () => {
  return (
   <>
     <S.BackgroundMobile>
       <S.SectionMobile>
        <Header/>
       </S.SectionMobile>
       <S.SectionMobile>
         <Banner/>
       </S.SectionMobile>
       <S.SectionMobile>
         <BannerIntelligence/>
         <BannerModules/>
       </S.SectionMobile>
       <S.SectionMobile>
         <BannerTabsMobile/>
         <FooterMobile/>
       </S.SectionMobile>
       <S.SectionMobile>
         <WhatsappMobile/>
         <CookiesMobile/>
       </S.SectionMobile>

     </S.BackgroundMobile>
   </>
  )
}

const Desktop = () => {

  return (
    <S.Background>
      <Header />
      <S.Section>
        <Banner />
        <BannerIntelligence />
      </S.Section>

      <S.Section>
        <S.Content>
          <BannerModules />
        </S.Content>
      </S.Section>

      <S.Section>
        <BannerTabs />
        <Footer />
      </S.Section>


      <Whatsapp />
      <Cookies />
    </S.Background>
  )
}

export default Main
