import React from 'react'
import { Card } from 'antd'
import * as S from './stylesMobile'
import Cockpit from '../../assets/banners-media/Cockpit.svg'
import Strategy from '../../assets/banners-media/Estratégia Candidatura.svg'
import CandManage from '../../assets/banners-media/Gestão da Candidatura.svg'
import PortManage from '../../assets/banners-media/Gestão de portifolio.svg'
import IntelligencePolitycal from '../../assets/banners-media/Inteligência Política.svg'
import TragetoryPolytical from '../../assets/banners-media/Trajetória Política.svg'
import { CardHeaderLast } from "./styles";

const Icons = [Cockpit, Strategy, CandManage, IntelligencePolitycal, TragetoryPolytical, PortManage]

const Titles = [
  'Cockpit do Candidato',
  'Estratégia de Candidatura',
  'Gestão da Candidatura',
  'Inteligência Política',
  'Trajetória Política',
  'Portifólio de Candidaturas',
]

const Contents = [
  'É o painel de controle do candidato, apresentando as principais ferramentas de forma intuitiva. Nele, é possível acessar e gerenciar informações importantes, como dados pessoais e de candidatura, menções sociais e comparativos entre candidatos.',
  'Neste ambiente, o usuário terá acesso a ferramentas de planejamento e estratégia que incluem funcionalidades como Análise Estratégica, Escopo de Candidatura, Metas por Zona Eleitoral e Simulação de Custos para cada cenário.',
  'Espaço para a gestão completa da campanha eleitoral, desde o planejamento inicial até a coordenação da agenda e programação de compromissos, incluindo controle e atribuições automatizadas dos usuários na conta.',
  'A Candidattus® oferece funcionalidades analíticas e preditivas avançadas para um diagnóstico preciso da campanha eleitoral. Inclui o monitoramento contínuo da opinião pública, identificação do candidato preferido e o perfil do candidato ideal.',
  'Oferecemos soluções orientadas por dados para a gestão de campanhas, ajustadas às necessidades específicas dos usuários. Proporcionamos dados em tempo real que avaliam o desempenho e as estratégias de campanha de diferentes entidades políticas.',
  'Ambiente exclusivo para agências, partidos políticos e doadores. Nesse espaço, os usuários podem visualizar detalhes sobre candidaturas patrocinadas ou gerais, organizadas nos Portfólios de Coligações.',
]


const CardsMobile = () => (
  <S.Main >
    {Icons.map((icon, index) => (
      <S.Container key={index} >
        {index === Icons.length - 1 && (
          <S.CardHeaderLast>
            <S.CardHeaderTextLast>
              Para Agências, Partidos e Doadores.
            </S.CardHeaderTextLast>
          </S.CardHeaderLast>
        )}
        <S.Content style={{marginLeft: index === Icons.length - 1 ? '10px' : '0',marginRight: index === Icons.length - 1 ? '10px' : '0'}}>
        <S.Icon>
          <img src={icon} alt={Titles[index]} />
        </S.Icon>
        <S.Title>{Titles[index]}</S.Title>
        <S.Text>{Contents[index]}</S.Text>
        </S.Content>
      </S.Container>
    ))}
  </S.Main>
)

export default CardsMobile
