import { Button } from 'antd'
import * as S from './styles'

interface CardAcquisitionProps {
  title: string
  description: string
  onClick: () => void
  bestOption?: boolean
  position?: number
}

export const CardAcquisition = ({ title, description, onClick, bestOption, position }: CardAcquisitionProps) => {
  return (
    <S.Wrapper position={position} best={bestOption}>
      <S.Content>
        {bestOption && <S.Badge>Melhor escolha</S.Badge>}
        <S.TitleCard>{title}</S.TitleCard>
        <S.Description>{description}</S.Description>
      </S.Content>

      <S.Button>
        <a href="https://meetings.hubspot.com/comercial-candidattus/agenda" target="_blank noreferrer">
          Agende uma Demonstração
        </a>
      </S.Button>
    </S.Wrapper>
  )
}
