import styled from 'styled-components';
import BREAKPOINTS from "../../styles/deviceBreakpoints";

export const Container = styled.div`

  .terms-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

`;

export const Max = styled.div`
  max-height: 400px;
  overflow-y: scroll;
  color: #e9e9f0;
  font-family: 'Roboto', 'SansSerif';
  font-size:16px;
  font-weight: 300;
  line-height: 1.6rem;
  letter-spacing: 0em;
  text-align: left;
  margin-top:15px ;
  margin-bottom: 22px;
  margin-left:25px ;
  margin-right: 23px;
`;

export const TermsContent = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background: #161717;
  width: 312px;
  border-radius: 1rem;

  /* Microsoft Edge */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /*Chrome*/
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

button {
    background-color: transparent;
    color: #ffc000;
    width: 13.25rem;
    height: 3.5rem;
    top: 33.75rem;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid #ffc000;
    gap: 0.625rem;
    align-self: center;
    cursor: pointer;
    margin-bottom: 0.75rem;
  }

`;
export const Title = styled.p`
  color: #E9E9F0;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.408px;
  margin-top:15px ;
  text-decoration-line: underline;

`;
