import { getItemStorage, setItemStorage } from 'utils/persistenceUtils'
import React, { useState, useEffect } from 'react'
import iconWarning from '../../assets/images/warning.svg'
import { TermsModal } from '../Modal'
import * as S from './styles'

const STORAGE_KEY = 'terms'

const Cookies = () => {
  const [isConsentGiven, setConsentGiven] = useState(true)
  const [isTermsModalOpen, setTermsModalOpen] = useState(false)

  useEffect(() => {
    const storageAccept = getItemStorage(STORAGE_KEY)

    if (!storageAccept) {
      setConsentGiven(false)
    }
  }, [])

  const handleConsent = () => {
    setConsentGiven(true)
    setItemStorage(STORAGE_KEY, 'true')
  }

  const handleOpenTermsModal = () => {
    setTermsModalOpen(true)
  }

  const handleCloseTermsModal = () => {
    setTermsModalOpen(false)
  }

  return isConsentGiven ? null : (
    <S.Container>
      <S.Content>
        <img src={iconWarning} alt="alert" />
        <S.CookieContent>
          Seu consentimento é importante! Ao prosseguir em nosso site, você está concordando com o uso de cookies. Caso
          tenha dúvidas, consulte as informações disponíveis em nossa
          <button type="button" onClick={handleOpenTermsModal} className="terms-link">
             Política de Cookies{' '}
          </button> .
        </S.CookieContent>
        <S.CookieButton type="button" onClick={handleConsent}>
          Aceitar
        </S.CookieButton>
        {isTermsModalOpen && <TermsModal onClose={handleCloseTermsModal} />}
      </S.Content>
    </S.Container>
  )
}

export default Cookies
