import styled from 'styled-components'
import BREAKPOINTS from "../../styles/deviceBreakpoints";

export const Background = styled.div`
  width: 100%;
  background: #0a0a0a;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`

export const ImageBox = styled.div`
  margin-top: 2rem;
  flex-direction: column;
  align-content: center;
  text-align: center;
  flex-shrink: 0;
    img{
      width: 320px;
      height: 210px;
    }
`
export const Image = styled.img`
  width: 360px;
  height: 246px;
  text-align: center;
`

export const ContentSlide = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContentText = styled.div`
  text-align: center;
  width: 100%;
  flex-shrink: 0;

`

export const Heading = styled.div`
  color: #E9E9F0;
  width:99%;
  flex-shrink: 0;
  text-align: center;
  font-family: Roboto;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.28px;
  margin-bottom:1rem;

`

export const Text = styled.p`
  color: #D9D9D9;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 48px;
`
