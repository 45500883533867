/* eslint-disable no-nested-ternary */
import { IoCloseSharp } from 'react-icons/io5'
import { FaCheck } from 'react-icons/fa6'
import { PACKAGES_BY_PLAN, PLAN_TYPES } from '../data/PLAN_DATA'

import * as S from './styles'
import { CardAcquisition } from '../Card'

export const AcquisitionTable = () => {
  return (
    <S.Wrapper>
      <S.Title>
        Escolha o <span>plano certo</span> para você!
      </S.Title>
      <S.Description>
        Descubra soluções sob medida para campanhas de impacto na plataforma Candidattus®. Oferecemos planos que atendem
        a cada perfil e necessidade, desde a visualização simplificada de informações do eleitorado até o gerenciamento
        completo de campanhas com nossa ferramenta exclusiva de gestão de candidaturas. Inicie hoje mesmo e converta
        dados em votos!
      </S.Description>

      <S.ContentTable>
        {PACKAGES_BY_PLAN.map((item, index) => {
          const firstOptionsKey: any = item.options

          return (
            <S.RowTable key={index}>
              <S.ColumnTable>
                <S.TitleBox>
                  <S.RowPackage>
                    <S.TitleTable>{item.package_type}</S.TitleTable>
                    <S.ListOptions>
                      {item?.options &&
                        Object.keys(item?.options).map((key, indexPlan) => {
                          const PlanByType = PLAN_TYPES.filter((plan: any) => plan.type === key)[0]
                          return (
                            <>
                              <S.OptionText key={key}>
                                {firstOptionsKey[key]}
                                {PlanByType && item?.define_package_type && (
                                  <CardAcquisition
                                    key={`${key}_plan_type_${indexPlan}`}
                                    title={PlanByType.title}
                                    description={PlanByType.description}
                                    onClick={() => console.log('PlaBy Type')}
                                    bestOption={PlanByType?.bestPlan}
                                    position={indexPlan}
                                  />
                                )}
                              </S.OptionText>
                            </>
                          )
                        })}
                    </S.ListOptions>
                  </S.RowPackage>
                  <S.ListPackage>
                    {item?.sub_package?.map((subItem: any) => (
                      <S.RowPackage>
                        <S.TitlePackage>{subItem.package_type}</S.TitlePackage>
                        <S.ListOptions>
                          {subItem?.options &&
                            Object.keys(subItem?.options).map(subKey => (
                              <S.OptionText key={subKey}>
                                {typeof subItem.options[subKey] === 'boolean' ? (
                                  subItem.options[subKey] ? (
                                    <FaCheck color="#FFC000" size={18} />
                                  ) : (
                                    <IoCloseSharp color="#D70708" size={22} />
                                  )
                                ) : (
                                  subItem.options[subKey]
                                )}
                              </S.OptionText>
                            ))}
                        </S.ListOptions>
                      </S.RowPackage>
                    ))}
                  </S.ListPackage>
                </S.TitleBox>
              </S.ColumnTable>
            </S.RowTable>
          )
        })}
      </S.ContentTable>
    </S.Wrapper>
  )
}
