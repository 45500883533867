import styled from 'styled-components'

export const Container = styled.div`
  margin-top:9rem;
  width:100%;
height: 100%;
  padding: 0;
  flex-shrink: 0;
  background: #0A0A0A;

`

export const Content = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;

`

export const Title = styled.div`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.08px;
  text-align: start;
  align-self: start;
  padding-left: 10px;

  .plano-certo{
    color: #FFC000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.08px;
  }
`
export const Text = styled.div`
  width: 100%;
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.07px;
  text-align: start;
  padding: 16px;
`
