const shades = {
  shade40: '#0A0A0A',
  shade30: '#111111',
  shade20: '#161717',
  shade10: '#1D1D1D',
}

const yellows = {
  yellow30: '#302301',
  yellow20: '#FFC000',
  yellow10: '#FFC000',
}

const reds = {
  red10: '#D70708',
}

const greens = {
  green10: '#00E23F',
}

const whites = {
  white20: '#EAEAEA',
  white10: '#FFFFFF',
}

const grays = {
  gray40: '#494949',
  gray30: '#5C5C5C',
  gray20: '#7B7B7B',
  gray10: '#E9E9F0',
}
export default {
  COLORS: {
    BLACK_1: '#000000',
    BLACK_2: '#181717', // TEXT BUTTON AND BACKGROUND SECOUNDARY DA APP
    BLACK_3: '#121212', // BACKGROUND AND TEXT SELECT
    BLACK_4: '#161717', // BACKGROUND MENU SIDEBAR PRIMARY
    BLACK_5: '#0A0A0A', // BACKGROUND MENU SIDEBAR SECOUNDARY AND BACKGROUND PRIMARY DA APP
    BLACK_6: '#111111', // ROW TABLE
    BLACK_7: '#1D1D1D', // ROW TABLE

    WHITE_1: '#FFFFFF', // BACKGROUND SECOUNDARY PROGRESS
    WHITE_2: '#E9E9F0', // TEXT PRIMARY AND BACKGROUND PRYMARY PROGRESS

    GRAY_1: '#CECECE', // PRIMARY TEXT
    GRAY_2: '#373737', // BACKGROUND SWITCH DISABLE
    GRAY_3: '#494949', // BARCKGROUND BUTTON DISABLE
    GRAY_4: '#7B7B7B', // TEXT BUTTON DISABLE
    GRAY_5: '#5C5C5C', // BORDER INPUTS, SEARCH AND SELECT AND GRAFICO QUADRADO ANALISE
    GRAY_6: '#D9D9D9', // ICON ESTRATEGIA CARREIRA

    GREEN_1: '#00E23F', // DASBOARD PRIMARY AND GRAFICO ESCUTA POLITICA AND EQUIPE DE CAMPANHA
    GREEN_2: '#419500', // GRAFICO CICLE ANALISE
    GREEN_3: '#059D4A', // BORDER CARD ESCUTA POLITICA AND RAIO-X TABLE

    YELLOW_1: '#FFC000', // PRIMARY
    YELLOW_2: '#B28801', // BACKGROUND GRAFICO QUADRADO ANALISE AND BORDER CARD ESCUTA POLITICA AND RAIO-X TABLE
    YELLOW_3: '#785A00', // GRAFICO ANALISE (POSSIVEL OPACITY)

    BROWN_1: '#302301', // BACKGROUND SWITCH AND BACKGROUND BUTTON SIDEBAR
    BROWN_2: '#813B0C', // RAIO-X TABLE

    RED_1: '#DD2D23', // TEXT ERROR AND BORDER INPUT ERROR
    RED_2: '#D70708', // BACKGROUND PROGRESS AND X DE ASSINATURAS AND BUTTON REJECT EQUIPE DE CAMPANHA
    RED_3: '#650202', // BACKGROUN GRAFICO QUADRADO ANALISE AND RAIO-X TABLE
    RED_4: '#C70A0A', // BORDER CARD ESCUTA POLITICA
    RED_5: '#FF0000', // BORDER EQUIPE DE CAMPANHA AND SLIDEGRAPH

    BLUE_1: '#2154ED', // BACKGROUND GRAFICO QUADRADO ANALISE
    BLUE_2: '#0288D1', // BACKGROUND GRAFICO QUADRADO ANALISE AND SLIDEGRAPH
    BLUE_3: '#223458', // BORDER CARD ESCUTA POLITICA AND RAIO-X TABLE
    BLUE_4: '#016DBA', // RAIO-X TABLE
    BLUE_5: '#155780', // SLIDEGRAPH

    PURPLE_1: '#9747FF', // GRAFICO CICLE ANALISE
    PURPLE_2: '#6E2F9D', // RAIO-X TABLE
    PURPLU_3: '#9B008C', // RAIO-XTABLE
    ...shades,
    ...greens,
    ...reds,
    ...whites,
    ...yellows,
    ...grays,
    BACKGROUND_DARK: '#0A0A0A',
    BACKGROUND_GREY: '#181717',
    OPTION_DARK: '#121212',
    FORM_TITLE: '#A6A6A6',
    TEXT_FORM: '#CECECE',
    BACKGROUND_YELLOW: '#FFC000',
    OPTION_HOVER: '#FFC000',
    ALERT: '#DD2D23',
    WHITE: '#FFFFFF',
    OFF_WHITE: '#E9E9F0',
    TITLE_MODAL_CONFIRMATION: '#EAEAEA',
    GREEN: '#00E23F',
  },

  FONT_FAMILY: {
    PRIMARY: 'Roboto, sans-serif',
    SECONDARY: 'Poppins, sans-serif',
  },
  FONT_SIZE: {
    SMALL: '0.75rem',
    X_SMALL: '1rem',
    MEDIUM: '1.25rem',
    LARGE: '1.5rem',
    X_LARGE: '2rem',
  },
  FONT_WEIGHT: {
    REGULAR: '400',
    MEDIUM: '600',
    BOLD: '700',
    BLACK: '900',
  },
  SPACING: {
    SMALL: '0.5rem',
    MEDIUM: '1rem',
    LARGE: '1.5rem',
    X_LARGE: '2rem',
  },

  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out',
  },
}
