import styled from 'styled-components'
import { Card } from 'antd'

export const Container = styled(Card)`
  width: 258px;
  height: 322px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #0A0A0A;
  display: flex;
  align-content: center;
  justify-content: center;
  border-style: none;
  margin-right: 24px;
  margin-bottom: 24px;
  position: relative;


`

export const CardHeaderLast = styled.div`
  border-radius: 8px 8px 0px 0px;
  background: #FFC000;
  width:258px;
  height: 26px;
  flex-shrink: 0;
  transform: translate(0,-25px);

`

export const CardHeaderTextLast = styled.div`
  color: #161717;
  font-family: Roboto;
  font-size:10px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  text-align: center;
  transform: translate(0, 9px);

`


export const Icon = styled.div`
  text-align: start;
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  margin-bottom: 16px;
`

export const Content = styled.div`

`

export const Main = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;

  &:first-child {
    padding-left: 10px;

  }

  &:last-child {
    padding-right: 32px;
  }

  /* Chrome, Edge and Safari */

  ::-webkit-scrollbar {

    height: 5px;
    width: 2px;

  }

  ::-webkit-scrollbar-track {
    border-radius: 16px;
    background-color: rgba(10, 10, 10, 0);
    margin-left: 150px;
    margin-right: 120px;
  }

  ::-webkit-scrollbar-track:hover {
    background-color: rgba(10, 10, 10, 0);
    margin-left: 150px;
    margin-right: 120px;
  }

  ::-webkit-scrollbar-track:active {
    background-color: rgba(10, 10, 10, 0);
    margin-left: 150px;
    margin-right: 120px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: rgba(233, 233, 240, 0);
    width: 5px;

  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(233, 233, 240, 0);
    width: 5px;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: rgba(233, 233, 240, 0);
    width: 5px;
  }

`

export const Title = styled.div`
  color: #D9D9D9;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
  width: 226px;
  margin-bottom: 1rem;
  &:last-child{
    padding-left: 5px;
    padding-right: 2px;
  }

`

export const Text = styled.p`
  color: #D9D9D9;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px; /* 100% */
  width: 226px;

`

export const Image = styled.div`
  text-align: end;
  img {
    width: 600px;
    height: 400px;
  }
`
