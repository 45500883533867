import React from 'react';
import * as S from './stylesMobile';
import icon from '../assets/icons/zap-icon.svg';

const WhatsappMobile = () => {
  return (
    <>
      <S.Zap>
        <a
          aria-label='Whatsapp'
          className="whatsapp-link"
          href="https://api.whatsapp.com/send?phone=5511974140793&text=Ol%C3%A1,%20Candidattus!%20Estou%20interessado%20nos%20servi%C3%A7os%20de%20intelig%C3%AAncia%20eleitoral%20que%20voc%C3%AAs%20oferecem%20e%20gostaria%20de%20saber%20como%20podem%20ajudar%20a%20transformar%20dados%20em%20votos%20para%20minha%20campanha.%20Podem%20me%20fornecer%20mais%20detalhes?"
          target="_blank" rel="noopener noreferrer"
        >
          <img src={icon} alt='zap' />
        </a>
      </S.Zap>
    </>
  );
};

export default WhatsappMobile;
