import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import * as S from './styles'
import * as SM from './mobile/stylesMobile'
import logoHeader from '../../assets/images/logo_header.png'
import { BuildModal } from '../ModalBuild'
import { BuildModalMobile } from '../ModalBuild/indexMobile'
import Bars from './assets/icons/bars.svg'
import Times from './assets/icons/times.svg'
import FacebookIcon from '../../assets/images/facebook-icon.svg'
import LinkedinIcon from '../../assets/images/linkedin-icon.svg'
import InstagramIcon from '../../assets/images/instagram-icon.svg'
import TwitterIcon from '../../assets/images/x.svg'

const Header = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 769px)' })

  return <>{isMobile ? <Mobile /> : <Desktop />}</>
}

const Mobile = () => {
  const [isTermsModalOpen, setTermsModalOpen] = useState(false)

  const handleOpenTermsModal = () => {
    setTermsModalOpen(true)
  }

  const handleCloseTermsModal = () => {
    setTermsModalOpen(false)
  }

  const [state, setState] = useState({ clicked: false })
  return (
    <SM.NavbarMobile>
      <SM.LogoMobile src={logoHeader} />
      <SM.MenuBarsMobile onClick={() => setState({ clicked: !state.clicked })}>
        <img alt="close" src={state.clicked ? Times : Bars} />
      </SM.MenuBarsMobile>

      {state.clicked && (
        <SM.ContentBackground>
          <SM.NavMobileContent>
            <SM.NavBarMobile>
              <SM.NavUlMobile>
                <SM.NavLiMobile className="yellow">Plataforma</SM.NavLiMobile>
                <SM.NavLiMobile onClick={handleOpenTermsModal}>Soluções</SM.NavLiMobile>
                {isTermsModalOpen && <BuildModalMobile onClose={handleCloseTermsModal} />}
                <SM.NavLiMobile onClick={handleOpenTermsModal}>Planos</SM.NavLiMobile>
                {isTermsModalOpen && <BuildModalMobile onClose={handleCloseTermsModal} />}
                <SM.NavLiMobile onClick={handleOpenTermsModal}>Blog</SM.NavLiMobile>
                {isTermsModalOpen && <BuildModalMobile onClose={handleCloseTermsModal} />}
                <SM.NavLiMobile onClick={handleOpenTermsModal}>Sobre nós</SM.NavLiMobile>
                {isTermsModalOpen && <BuildModalMobile onClose={handleCloseTermsModal} />}
              </SM.NavUlMobile>
            </SM.NavBarMobile>
            <SM.ButtonContainer>
              <SM.ButtonMobile type="button">
                <a href="https://portal.candidattus.io/" target="_blank" rel="noopener noreferrer">
                  Acessar Plataforma
                </a>
              </SM.ButtonMobile>
            </SM.ButtonContainer>
            <SM.Social>
              Siga-nos:
              <SM.NavSocialLista>
                <SM.NavSocialItem>
                  {' '}
                  <a
                    href="https://www.facebook.com/candidattus"
                    aria-label="insta-icon"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={FacebookIcon} alt="IG icon" />
                  </a>
                </SM.NavSocialItem>
                <SM.NavSocialItem>
                  {' '}
                  <a
                    href="https://www.linkedin.com/company/candidattus/"
                    aria-label="insta-icon"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={LinkedinIcon} alt="IG icon" />
                  </a>
                </SM.NavSocialItem>
                <SM.NavSocialItem>
                  {' '}
                  <a
                    href="https://www.instagram.com/candidattus/"
                    aria-label="insta-icon"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={InstagramIcon} alt="IG icon" />
                  </a>
                </SM.NavSocialItem>
                <SM.NavSocialItem>
                  {' '}
                  <a
                    href="https://twitter.com/candidattus"
                    aria-label="insta-icon"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={TwitterIcon} alt="IG icon" />
                  </a>
                </SM.NavSocialItem>
              </SM.NavSocialLista>
            </SM.Social>
          </SM.NavMobileContent>
        </SM.ContentBackground>
      )}
    </SM.NavbarMobile>
  )
}

const Desktop = () => {
  const [isTermsModalOpen, setTermsModalOpen] = useState(false)
  const path = useLocation()?.pathname

  const handleOpenTermsModal = () => {
    setTermsModalOpen(true)
  }

  const handleCloseTermsModal = () => {
    setTermsModalOpen(false)
  }

  return (
    <S.NavbarContainer>
      <S.Content>
        <S.Logo src={logoHeader} />
        <div className="two">
          <S.NavbarLink active={path === '/'}>
            <Link to="/">Plataforma</Link>
          </S.NavbarLink>
          <S.NavbarLinkNot onClick={handleOpenTermsModal}>Soluções</S.NavbarLinkNot>
          {isTermsModalOpen && <BuildModal onClose={handleCloseTermsModal} />}
          <S.NavbarLink active={path === '/planos'}>
            <Link to="/planos">Planos</Link>
          </S.NavbarLink>
          <S.NavbarLinkNot onClick={handleOpenTermsModal}>Blog</S.NavbarLinkNot>
          {isTermsModalOpen && <BuildModal onClose={handleCloseTermsModal} />}
          <S.NavbarLinkNot onClick={handleOpenTermsModal}>Sobre nós</S.NavbarLinkNot>
          {isTermsModalOpen && <BuildModal onClose={handleCloseTermsModal} />}
          <S.Button type="button">
            <a href="https://portal.candidattus.io/" target="_blank" rel="noopener noreferrer">
              Acessar Plataforma
            </a>
          </S.Button>
        </div>
      </S.Content>
    </S.NavbarContainer>
  )
}

export default Header
