import React from 'react'
import * as S from './styles'
import TabsCustom from '../Tabs'
import tab1 from '../../assets/banners-media/candidato-preferido.gif'
import tab2 from '../../assets/banners-media/politica-vox.gif'
import tab3 from '../../assets/banners-media/arquetipo-politico.gif'
import tab4 from '../../assets/banners-media/visibilidade-de-dados.gif'
import tab5 from '../../assets/banners-media/gestao-de-campanha.gif'

import { TABS_DATA } from "./DATA/DATA";

const labels = [
  'Candidato Preferido',
  'Politica Vox®',
  'Arquétipo Político®',
  'Diagnóstico Político',
  'Portfólio de Candidaturas',
]

const contents = [
  <S.ContainerTab>
    <S.ContentTab>
      <S.TitleTab>Candidato Preferido</S.TitleTab>
      <br/>
      <S.Subtitle>Descubra a preferência dos eleitores com evidências sólidas</S.Subtitle>
      <S.TextTab>
        Esta funcionalidade interpreta as expectativas e características demográficas, socioeconômicas e de
        personalidade do eleitorado-alvo, propondo insights direcionados. Monitora também as mudanças sutis nas
        preferências dos eleitores e identifica aspectos baseados em pesquisas de opinião, perfil demográfico, entre
        outras características.
      </S.TextTab>
    </S.ContentTab>
    <S.Gif>
      <img src={tab1} alt="Gif" />
    </S.Gif>
  </S.ContainerTab>,
  <S.ContainerTab>
    <S.ContentTab>
      <S.TitleTab>Politica Vox®</S.TitleTab>
      <br/>
      <S.Subtitle>Monitore e analise de sentimentos da sua candidatura</S.Subtitle>
      <S.TextTab>
        Integra múltiplos recursos para capturar o pulso do eleitorado e entender as percepções relacionadas a temas políticos e eleitorais. A Candidattus® monitora e analisa em tempo real o sentimento e as opiniões das pessoas em redes sociais, fóruns e outras plataformas online. Identificamos, assim, tendências de discussão e temas quentes, permitindo que campanhas ajustem suas mensagens.
      </S.TextTab>
    </S.ContentTab>
    <S.Gif>
      <img src={tab2} alt="Gif" />
    </S.Gif>
  </S.ContainerTab>,
  <S.ContainerTab>
    <S.ContentTab>
      <S.TitleTab>Arquétipo Político®</S.TitleTab>
      <br />
      <S.Subtitle>Conheça as qualidades ideais do candidato com os modelos de personalidade</S.Subtitle>
      <S.TextTab>
        Esta funcionalidade identifica características do candidato ideal com modelo de personalidade. A integração de
        arquétipos políticos com uma metodologia proprietária permite análises detalhadas e únicas das personalidades
        políticas, enriquecidas por assessments específicos e técnicas de social listening de última geração.
      </S.TextTab>
    </S.ContentTab>
    <S.Gif>
      <img src={tab3} alt="Gif" />
    </S.Gif>
  </S.ContainerTab>,
  <S.ContainerTab>
    <S.ContentTab>
      <S.TitleTab>Diagnóstico Político</S.TitleTab>
      <br />
      <S.Subtitle>Visualização intuitiva de padrões, tendências e exceções nos dados</S.Subtitle>
      <S.TextTab>
        O diagnóstico é realizado por meio da coleta de dados relacionados às eleições, abrangendo desde pesquisas de opinião e características demográficas dos eleitores até detalhes sobre candidatos e partidos. Além disso, incorporamos dados de Big Data e informações gerais, proporcionando a identificação contextualizada do ambiente eleitoral.
      </S.TextTab>
    </S.ContentTab>
    <S.Gif>
      <img src={tab4} alt="Gif" />
    </S.Gif>
  </S.ContainerTab>,
  <S.ContainerTab>
    <S.ContentTab>
      <S.TitleTab>Portfólio de Candidaturas</S.TitleTab>
      <br />
      <S.Subtitle>Compilado de dados acionáveis sobre candidatos e partidos</S.Subtitle>
      <S.TextTab>
        Oferecemos uma compilação de informações sobre candidaturas e outras articulações políticas adotadas pela legenda para a disputa do pleito. Proporcionamos dados em tempo real que avaliam o desempenho e as estratégias de campanha de diferentes entidades políticas.
      </S.TextTab>
    </S.ContentTab>
    <S.Gif>
      <img src={tab5} alt="Gif" />
    </S.Gif>
  </S.ContainerTab>
]

const BannerTabs = () => {
  return (
    <>
      <S.Container>
        <S.Content>
          <div className="text-content">
            <S.Title>Descubra nossas soluções proprietárias</S.Title>
            <S.Text>
              A plataforma Candidattus® possui uma arquitetura robusta e exclusiva que permite aos usuários adaptar estratégias e gerenciar crises com facilidade. Nosso motor de processamento integra componentes divididos em 6 módulos que, juntos, oferecem uma visão abrangente de todos os aspectos relevantes em uma campanha eleitoral.
            </S.Text>
          </div>
          <TabsCustom labels={labels} content={contents} />
        </S.Content>
      </S.Container>
    </>
  )
}

export default BannerTabs
