import styled from 'styled-components'

export const TopButton = styled.div`
      .ant-back-top {
        bottom: 100px;
        position: sticky;
        z-index: 10;
        }

       .ant-back-top-inner {
          line-height: 40px;
          border-radius: 4px;
          text-align: center;
          font-size: 20px;
          height: 40px;
          width: 40px;
          transform: translate(250px,-10px);
        }
`
