import { createGlobalStyle } from 'styled-components'
import BREAKPOINTS from './deviceBreakpoints'
import theme from './theme'

export const GlobalStyle = createGlobalStyle`

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    :root {
        font-size: 16px;

        @media (max-width: ${BREAKPOINTS.WIDHT.MD}) , (max-height: ${BREAKPOINTS.HEIGHT.MEDIUM}) {
            font-size: 14px;
        };

        @media (max-width: ${BREAKPOINTS.WIDHT.SM}) , (max-height: ${BREAKPOINTS.HEIGHT.SMALL}) {
            font-size: 12px;
        };

        @media (max-width: ${BREAKPOINTS.WIDHT.XS}) , (max-height: ${BREAKPOINTS.HEIGHT.EXTRASMALL}) {
            font-size: 10px;
        };
    }

    body {
        background-color: ${theme.COLORS.BACKGROUND_DARK};
        color: ${theme.COLORS.WHITE};
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;

    }

    a {
        text-decoration: none;
    }

    button {
        font-family: 'Roboto', serif;
        font-size: 1rem;
        outline: none;
    }

    button, a {
        cursor: pointer;
        transition: filter 0.2s;
    }

    button:hover, a:hover {
        filter: brightness(0.9);
    }

    ::-webkit-scrollbar {

        width: 0.4rem;
        height: 0.4rem;
    }

    ::-webkit-scrollbar-track {

        background-color: #161717;
    }

    ::-webkit-scrollbar-thumb {

        background-color: #000000;
    }

    #filter-drawer {
        background: #0a0a0a !important;
    }
    button.ant-drawer-close {
        color: #fff !important;
    }
`
