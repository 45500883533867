import React from "react";
import { useMediaQuery } from "react-responsive";
import * as S from './styles'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import FooterMobile from '../../components/Footer/indexMobile'
import Whatsapp from '../../components/Whatsapp'
import WhatsappMobile from '../../components/Whatsapp/mobile/indexMobile'
import Cookies from '../../components/Cookies'
import CookiesMobile from '../../components/Cookies/indexMobile'
import Plans from "../../components/Plans/index";

const PlansPage = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 769px)' });

  return (
    <>
      {isMobile ? <Mobile /> : <Desktop />}

    </>
  )
}

const Mobile = () => {
  return (
    <>
      <S.BackgroundMobile>
        <S.SectionMobile>
                  <Header/>

        </S.SectionMobile>
        <S.SectionMobile>

                  <Plans />

        </S.SectionMobile>
                <S.SectionMobile>
                  <FooterMobile/>



        </S.SectionMobile>




          <WhatsappMobile/>
          <CookiesMobile/>

      </S.BackgroundMobile>
    </>
  )
}

const Desktop = () => {

  return (
    <S.Background>
      <Header />
      <S.Section>
        <Plans />
        <Footer />
      </S.Section>
      <Whatsapp />
      <Cookies />
    </S.Background>
  )
}

export default PlansPage;
