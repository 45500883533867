import styled from 'styled-components';
import BREAKPOINTS from "../../styles/deviceBreakpoints";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

export const Content = styled.div`
  margin-bottom: 2rem;
  position: fixed;
  bottom: 0;
  width: 80.375rem;
  height: 5.375rem;
  box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  background: #161717;
  z-index: 1000;

  border-style: none;
  text-underline: white;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  align-items: center;
  font-size: 0.8rem;
margin-left: auto;
margin-right: auto;
  img {
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export const CookieContent = styled.div`
  font-family: Roboto;
  font-size: 1.02em;
  font-weight: 400;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  color: #e9e9f0;
  cursor: pointer;
  width: 40rem;
  grid-column: 2;

  .terms-link {
    font-size: 1.02em;
    color: white;
    background: transparent;
    font-weight: 700;
    text-decoration: underline;
    border-style: none;
    text-underline: white;
    cursor: pointer;
    margin-left: 0.3rem;
  }

`;

export const CookieButton = styled.button`
  width: 13.25rem;
  height: 3.37rem;
  border-radius: 0.5rem;
  border-style: none;
  background: #ffc000;
  font-family: Roboto;
  font-size: 0.9rem;
  font-weight: 400;
  cursor: pointer;
  color: #161717;
  justify-self: start;
  padding: 1rem;
  margin-right: 1rem;
  text-decoration: none;

`;
