import React from 'react'
import { useMediaQuery } from "react-responsive";
import * as S from './styles'
import * as SM from './stylesMobile'
import Cards from '../Cards/index'
import CardsMobile from '../Cards/indexMobile'
import ModuleImg from '../../assets/banners-media/modules.png'
import fundo from './assets/images/OSXDCD1 1 1.svg'

const BannerModules = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 769px)' });

  return (
    <>
      {isMobile ? <Mobile /> : <Desktop />}

    </>
  )
}

const Mobile = () => {
  return (
    <>
      <SM.Container>
          <SM.Content>
            <SM.TextBox>
              <SM.Title>Módulos</SM.Title>
            <SM.Image>
              <img src={fundo} alt="Laptop Aberto"/>
            </SM.Image>
              <SM.Text>
                O motor de processamento da plataforma Candidattus® integra componentes divididos em 6 módulos que, juntos,
                oferecem uma visão abrangente de todos os aspectos relevantes em uma campanha eleitoral.
              </SM.Text>
            </SM.TextBox>
          </SM.Content>
            <SM.CardConteiner>
              <CardsMobile />
            </SM.CardConteiner>
      </SM.Container>
    </>
  )
}

const Desktop= () => {
  return (
    <S.Container>
      <S.Content>
        <S.Text>
          <S.Title>Módulos</S.Title>
          A plataforma Candidattus® possui uma arquitetura robusta e exclusiva para equipes de campanha que querem compreender o cenário político, adaptar estratégias e triunfar no mundo digital em constante evolução. Nosso motor de processamento integra componentes com propósitos claros
          e distintos, operando de maneira integrada para buscar resultados transformadores.
        </S.Text>
        <S.Image>
          <img src={ModuleImg} alt="Laptop Aberto" />
        </S.Image>
      </S.Content>
      <S.CardConteiner>
        <Cards />
      </S.CardConteiner>
    </S.Container>
  )
}

export default BannerModules
