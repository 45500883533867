/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css, styled } from 'styled-components'
import { CustomSliderProps } from './index'

type WrapperProps = Pick<CustomSliderProps, 'arrowPosition' | 'countSlide'>

const ArrowModifier = {
  center: () => css`
    .slick-prev {
      transform: translate(80px, -260px);
    }

    .slick-next {
      transform: translate(-80px, -260px);
    }
  `,
  start: () => css``,
  end: (countSlide = 3) => css`
    .slick-prev {
      position: absolute;
      left: ${countSlide && `calc(43% - ${countSlide}%)`};
    }

    .slick-next {
      position: absolute;
      right: ${countSlide && `calc(43% - ${countSlide}%)`};
    }
  `,
}

export const Wrapper = styled.main<WrapperProps>`
  ${({ arrowPosition, countSlide }) => css`
    position: relative;
    width: 100%;

    .slick-arrow {
      transition: unset;
    }

    .slick-prev,
    .slick-next {
      display: none;
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 102%;
      display: block;
      width: 15px;
      height: 22px;
      padding: 0;
      cursor: pointer;
      border: none;
      outline: none;
      z-index: 200;
    }

    .slick-prev {
      &.slick-disabled {
        opacity: 0;
      }
    }

    .slick-next {
      &.slick-disabled {
        opacity: 0;
      }
    }

    .slick-dotted.slick-slider {
      margin-bottom: 1.875rem;
      padding: 0;
    }

    .slick-dots {
      position: absolute;
      bottom: calc(-5%);
      display: block;
      width: 100%;
      padding: 0;
      margin: auto;
      list-style: none;
      text-align: center;
    }

    .slick-dots li {
      position: relative;
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: 0 6px;
      padding: 0;
      border-radius: 50%;
      background: #e9e9f0;
      overflow: hidden;

      cursor: pointer;

      &.slick-active {
        background: #ffc000;
        padding: 1.5px;

        &:before {
          opacity: 1;
        }
      }
    }

    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      padding: 0;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
    }

    .slick-dots li button:hover,
    .slick-dots li button:focus {
      outline: none;
      padding: 0;
    }

    .slick-dots li button:hover:before,
    .slick-dots li button:focus:before {
      opacity: 1;
      padding: 0;
    }

    .slick-dots li button:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 1.25rem;
      height: 1.25rem;
      opacity: 1;
      padding: 0;
      content: url('assets/not.svg');
    }

    .slick-dots li.slick-active button:before {
      margin-top: 0.0625rem;
      padding: 0;
      opacity: 1;
      content: url('assets/bullet.svg');
    }

    ${arrowPosition && ArrowModifier[arrowPosition](countSlide)};
  `}
`
