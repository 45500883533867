import React from 'react';
import * as STable from './stylesTabela';

export const PlanoPrata = () => {


  return (
    <STable.Container>
    <STable.Table>
    <STable.Impar>
      <p>Fidelidade de 6 meses</p>
    </STable.Impar>
      <STable.Par>
        <p>1 Assinatura mensal</p>
    </STable.Par>
      <STable.Impar>
        <STable.Subtitle>Cockpit de Candidatura</STable.Subtitle>
        <p>Cockpit</p>
      </STable.Impar>
      <STable.Par>
        <STable.Subtitle>Estratégia de Candidatura</STable.Subtitle>
        <p>Análise Estratégica</p>
        <p>Metas de Candidatura</p>
      </STable.Par>
      <STable.Impar>
        <STable.Subtitle>Inteligência Política</STable.Subtitle>
        <p>Diagnóstico Político</p>
        <p>Politica Vox® Candidatura</p>
      </STable.Impar>
      <STable.Par>
        <STable.Subtitle>Trajetória Política</STable.Subtitle>
        <p>Perfil Político</p>
      </STable.Par>
    </STable.Table>
    </STable.Container>
  );
};
export const PlanoOuro = () => {
  return (
    <STable.Container>
      <STable.Table>
        <STable.Impar>
          <p>Fidelidade de 12 meses</p>
        </STable.Impar>
        <STable.Par>
          <p>Min 1 Assinatura mensal</p>
        </STable.Par>
      </STable.Table>
        <STable.Contem>
          Contém tudo do plano BÁSICO e mais:
        </STable.Contem>
      <STable.Table>
        <STable.Impar>
          <STable.Subtitle>Gestão da Candidatura</STable.Subtitle>
          <p>Equipe - 1 assessor</p>
        </STable.Impar>
        <STable.Par>
          <STable.Subtitle>Inteligência Política</STable.Subtitle>
          <p>Candidato Preferido</p>
          <p>Arquétipo Político®</p>
        </STable.Par>
        <STable.Impar>
          <STable.Subtitle>Trajetória Política</STable.Subtitle>
          <p>Estratégia de Carreira</p>
        </STable.Impar>
      </STable.Table>
    </STable.Container>
  );
};
export const PlanoPlatina = () => {


  return (
    <STable.Container>
      <STable.Table>
        <STable.Impar>
          <p>Fidelidade de 12 meses</p>
        </STable.Impar>
        <STable.Par>
          <p>Min. 50 Assinatura mensal</p>
        </STable.Par>
      </STable.Table>
      <STable.Contem>
        Contém tudo do plano AVANÇADO e mais:
      </STable.Contem>
      <STable.Table>
        <STable.Impar>
          <STable.Subtitle>Gestão da Candidatura</STable.Subtitle>
          <p>Planejamento</p>
          <p>Programação</p>
          <p>Equipe - até 5 pessoas</p>
        </STable.Impar>
        <STable.Par>
          <STable.Subtitle>Gestão de Equipe</STable.Subtitle>
          <p>SkillMatch Pessoal</p>
          <p>Plano de Carreira</p>
        </STable.Par>
        <STable.Impar>
          <STable.Subtitle>Portfólio de Candidatura</STable.Subtitle>
          <p>Candidaturas Patrocinadas</p>
          <p>Todas as Candidaturas</p>
          <p>Politica Vox® Partido</p>
        </STable.Impar>
      </STable.Table>
    </STable.Container>
  );
};
export const PlanoDiamante = () => {


  return (
    <STable.Container>
      <STable.Table>
        <STable.Impar>
          <p>Fidelidade de 12 meses</p>
        </STable.Impar>
        <STable.Par>
          <p>Min. 200 Assinatura mensal</p>
        </STable.Par>
      </STable.Table>
      <STable.Contem>
        Contém tudo dos outros planos.
      </STable.Contem>
      <STable.Table>
        <STable.Impar>
          <STable.Subtitle>Estratégia de Candidatura</STable.Subtitle>
          <p>Metas por Zona Eleitoral</p>
          <p>Simulação de Custos</p>
        </STable.Impar>
        <STable.Par>
          <STable.Subtitle>Gestão da Candidatura</STable.Subtitle>
          <p>Equipe - até 10 pessoas</p>
        </STable.Par>
      </STable.Table>
    </STable.Container>
  );
};


