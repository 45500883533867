import styled from 'styled-components'

export const TopButton = styled.div`
    .ant-back-top {
      bottom: 100px;
      position: sticky;
      z-index: 10;
      }

     .ant-back-top-inner {
        height: 40px;
        width: 40px;
        line-height: 40px;
        border-radius: 4px;
        text-align: center;
        font-size: 20px;
       transform: translate(1100px,-15px);
      }
`
