import styled from 'styled-components';
import BREAKPOINTS from "../../styles/deviceBreakpoints";

export const Container = styled.div`

  .terms-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .terms-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #161717;
    width: 29.75rem;
    border-radius: 1rem;
    color: #e9e9f0;
    font-family: 'Roboto', 'SansSerif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1875rem;
    letter-spacing: 0em;
    text-align: left;
    padding: 1.25rem 3.125rem;


    /* Microsoft Edge */
    -ms-overflow-style: none;
    scrollbar-width: none;

    /*Chrome*/
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }
    .max {
      max-height: 38.75rem;
      overflow-y: scroll;
      @media (max-width: ${BREAKPOINTS.WIDHT.XL}) {
        max-height: 27rem;
      }
    }
  }

  .terms-content h1 {
    font-family: Roboto;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: -0.017em;
    text-align: center;
    width: 12.3125rem;
    height: 1.75rem;
    color: #E9E9F0;
    text-decoration: underline #e9e9f0;
    margin-left: 4.25rem;
    @media (max-width: ${BREAKPOINTS.WIDHT.XL}) {
      font-size: 1.3rem;
    }
  }

  .terms-content button {
    background-color: transparent;
    color: #ffc000;
    width: 13.25rem;
    height: 3.5rem;
    top: 33.75rem;
    left: 9.375rem;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid #ffc000;
    gap: 0.625rem;
    cursor: pointer;
    margin-left: 4.25rem;
    margin-bottom: 0.75rem;
    @media (max-width: ${BREAKPOINTS.WIDHT.XL}) {
      width: 12.25rem;
      height: 3.5rem;
      margin-top: 2rem;
    }
  }
`;

export const Subtitle = styled.p`
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;
