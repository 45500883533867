import styled from 'styled-components';
import BREAKPOINTS from "../../styles/deviceBreakpoints";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.26);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`

  justify-content: center;
  align-content: center;
  background: #161717;
  width: 512px;
  height: 272px;
  border-radius: 1rem;

  /* Microsoft Edge */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /*Chrome*/

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }


`

export const Heading = styled.p`

    color: #E9E9F0;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.408px;
    text-decoration: underline #e9e9f0;
    align-self: center;

    margin-top: 38px;
    margin-bottom:30px ;
    margin-left:139px ;
    margin-right:137px ;
  `
export const Text = styled.p`

  color: #E9E9F0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  width: 464px;
  align-self: center;
  margin-top: 30px;
  margin-bottom: 48px;
  margin-left: 24px;
  margin-right: 24px;
`
export const Button = styled.button`
  background: transparent;
  width: 212px;
  padding: 16px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #FFC000;
  color: #FFC000;
  /* M3/body/large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
  margin-bottom: 24px;
  margin-left: 150px;
  margin-right: 150px;

`

