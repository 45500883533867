import React from 'react'
import { useMediaQuery } from "react-responsive";
import { CustomSlider } from 'components/Slider/index'
import { CustomSliderMobile } from 'components/Slider/indexMobile'
import Imagem from '../../assets/banners-media/img-banner2.gif'
import * as S from './styles'
import * as SM from './stylesMobile'
import { SLIDE_DATA } from './DATA_SLIDER/DATA'
import { SLIDE_DATA_MOBILE } from './DATA_SLIDER/DATAMOBILE'

const BannerIntelligence = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 769px)' });

  return (
    <>
      {isMobile ? <Mobile /> : <Desktop />}

    </>
  )
}
const Mobile = () => {

  const totalSlides = SLIDE_DATA_MOBILE.length

  return (
    <SM.Background>
      <SM.Content>
        <SM.ContentSlide>
          <CustomSliderMobile countSlide={totalSlides} >
            {SLIDE_DATA_MOBILE.map((data, index) => (
              <SM.ContentText key={data.title} >
                <SM.Heading>{data.title}</SM.Heading>
                <SM.Text>{data.text}</SM.Text>
              </SM.ContentText>
            ))}
          </CustomSliderMobile>
        </SM.ContentSlide>
      </SM.Content>
<SM.ImageBox>

        <img src={Imagem} alt="debate" />
</SM.ImageBox>

    </SM.Background>
  )
}


const Desktop = () => {
  const totalSlides = SLIDE_DATA.length

  const countSlide = (index: number) => {
    if (index === 0) {
      return 'first_slide'
    }
    if (index === totalSlides - 1) {
      return 'last_slide'
    }
    return ''
  }

  return (
    <S.Background>
      <S.Content>
        <S.ImageBox>
          <S.Image src={Imagem} alt="debate" />
        </S.ImageBox>

        <S.ContentSlide>
          <CustomSlider countSlide={totalSlides} arrowPosition="end" >
            {SLIDE_DATA.map((data, index) => (
              <S.ContentText key={data.title} className={`slide-${countSlide(index)}`}>
                <S.Heading>{data.title}</S.Heading>
                <S.Text>{data.text}</S.Text>
              </S.ContentText>
            ))}
          </CustomSlider>
        </S.ContentSlide>
      </S.Content>
    </S.Background>
  )
}

export default BannerIntelligence
