import React from 'react'
import { useMediaQuery } from 'react-responsive'
import * as S from './styles'
import * as SM from './stylesMobile'
import PlanCard from '../PlansCards'
import { DesktopTemplate } from './Template/Desktop'

const Plans = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 769px)' })

  return <>{isMobile ? <Mobile /> : <Desktop />}</>
}

const Mobile = () => {
  return (
    <>
      <SM.Container>
        <SM.Content>
          <SM.Title>
            {' '}
            Escolha o<span className="plano-certo"> plano certo </span>para você!
          </SM.Title>
          <SM.Text>
            Descubra soluções sob medida para campanhas de impacto na plataforma Candidattus®. Oferecemos planos que
            atendem a cada perfil e necessidade, desde a visualização simplificada de informações do eleitorado até o
            gerenciamento completo de campanhas com nossa ferramenta exclusiva de gestão de candidaturas. Inicie hoje
            mesmo e converta dados em votos!
          </SM.Text>
          <PlanCard />
        </SM.Content>
      </SM.Container>
    </>
  )
}

const Desktop = () => {
  return (
    <>
      <S.Container>
        <DesktopTemplate />
      </S.Container>
    </>
  )
}

export default Plans
