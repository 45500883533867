import styled, { css } from 'styled-components'
import BREAKPOINTS from '../../styles/deviceBreakpoints'

export const Background = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #1D1D1D;
`

export const BackgroundMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
height: 100vh;
`

export const SectionMobile = styled.div<{ background?: string; fullWidth?: boolean }>`
  ${({ background, fullWidth }) => css`
    max-width: ${!fullWidth && '100%'};
    width: ${fullWidth && '100%'};
    background: ${background};
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

export const Section = styled.div<{ background?: string; fullWidth?: boolean }>`
  ${({ background, fullWidth }) => css`
    max-width: ${!fullWidth && '1336px'};
    width: ${fullWidth && '100%'};
    background: ${background};
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`

export const Content = styled.div`
  max-width: 1336px;
`
