import styled from 'styled-components'
import fundo from './assets/images/OSXDCD1 1 1.svg'

export const Container = styled.div`
  width:100%;
  height: 794px;
  padding: 0;
  flex-shrink: 0;
  background: #161717;

`

export const Content = styled.div`
width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

`

export const Title = styled.div`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 1rem;
  width: 100%;
`

export const TextBox = styled.div`
  width: 100%;
  height: 114px;
  padding: 1rem;
`

export const Text = styled.div`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  flex-shrink: 0;
  width: 100%;
  height: 114px;
  flex-shrink: 0;

`

export const CardConteiner = styled.div`
  margin-top: calc(5%);
  display: flex;
align-content: center;
  width: 100%;
    transform: translate(0,270px);

  /* Chrome, Edge and Safari */

  ::-webkit-scrollbar {
    height: 5px;
    width: 2px;

  }

  ::-webkit-scrollbar-track {
    border-radius: 16px;
    background-color: rgba(178, 175, 175, 0);
    margin-left: 150px;
    margin-right: 120px;
  }

  ::-webkit-scrollbar-track:hover {
    background-color: rgba(10, 10, 10, 0);
    margin-left: 150px;
    margin-right: 120px;
  }

  ::-webkit-scrollbar-track:active {
    background-color: rgba(10, 10, 10, 0);
    margin-left: 150px;
    margin-right: 120px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: rgba(233, 233, 240, 0);
    width: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(233, 233, 240, 0);
    width: 5px;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: rgba(233, 233, 240, 0);
    width: 5px;
  }

`

export const Image = styled.div`
  width: 90%;

  img{

  width: 100%;

  flex-shrink: 0;
  background: url(${fundo}) lightgray -36.021px -58.752px / 135.27% 160.7% no-repeat;

  }
`
