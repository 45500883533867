import React from 'react'
import { ConfigProvider } from 'antd'

export const AntProvider = ({ children }: { children: React.ReactNode }) => (
  <ConfigProvider

    theme={{
      components: {
        Select: {
          borderRadius: 8,
          borderRadiusLG: 4,
          borderRadiusSM: 6,
          optionSelectedBg: '#fcc100',
          optionActiveBg: '#FFC000',
        },
        Menu: {
          itemHoverBg: 'red',
          darkItemHoverColor: 'yellow',
          darkItemSelectedColor: 'green',
        },
        Tabs: {
          inkBarColor: 'rgb(255, 192, 0)',
          itemSelectedColor: 'rgb(255, 255, 255)',
          itemColor: 'rgb(233, 233, 240)',
          colorBorderSecondary: 'rgb(46, 46, 46)',
          fontSize: 16,
          lineWidth: 1,
          lineHeight: 1,
        },
      },
    }}
  >
    {children}
  </ConfigProvider>
)
