import React from "react";
import { useMediaQuery } from "react-responsive";
import { BackTop } from 'antd';
import * as S from './style'
import * as SM from './styleMobile'
import TopIcon from './assets/icons/arrow-up.svg'

export const Top = () =>{
  const isMobile = useMediaQuery({ query: '(max-width: 769px)' });

  return (
    <>
      {isMobile ? <Mobile /> : <Desktop />}

    </>
  )
}
export const Mobile = () =>{

  return(
    <SM.TopButton>
        <BackTop className="ant-back-top-inner">
            <img src={TopIcon} alt='Botão de Subir ao Topo'/>
        </BackTop>
    </SM.TopButton>
  )
}
export const Desktop = () =>{
  return(

    <S.TopButton>
      <BackTop className="ant-back-top-inner">
        <img src={TopIcon} alt='Botão de Subir ao Topo'/>
      </BackTop>
    </S.TopButton>
  )
}


