import styled from 'styled-components'

export const Container = styled.div`
  width:100%;
  background: #0A0A0A;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  bottom: 0;
`;

export const Content = styled.div`

margin-left: 2rem;

`

export const Title = styled.div`
  width: 100%;
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const Subtitle = styled.div`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
  width: 296px;
  margin-bottom: 1rem;
`;

export const Text = styled.div`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 100%;
  margin-bottom: 1rem;
`;

export const TextTab = styled.p`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  width: 100%;
  margin-bottom: 1rem;
`;

export const Gif = styled.div`
  margin-bottom: 22px;
  img{
    width: 95%;
  }
`;

export const ContainerTab = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`

export const ContentTab = styled.div`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  margin-right: 2rem;

`
