import React, { useState } from 'react'
import EmailLink from 'components/EmailTo'
import { Top } from '../BackTop'
import logoHeader from '../../assets/images/logo_header.png'
import FacebookIcon from '../../assets/images/facebook-icon.svg'
import LinkedinIcon from '../../assets/images/linkedin-icon.svg'
import InstagramIcon from '../../assets/images/instagram-icon.svg'
import TwitterIcon from '../../assets/images/x.svg'
import { TermsModal } from '../Modal'
import * as S from './styles'


const Footer = () => {
  const [isConsentGiven, setConsentGiven] = useState(false)
  const [isTermsModalOpen, setTermsModalOpen] = useState(false)

  const handleConsent = () => {
    setConsentGiven(true)
  }

  const handleOpenTermsModal = () => {
    setTermsModalOpen(true)
  }

  const handleCloseTermsModal = () => {
    setTermsModalOpen(false)
  }

  if (isConsentGiven) {
    return null
  }

  return (
    <S.Container>
      <S.Footer>
<Top/>
        <S.LogoFooter src={logoHeader} />
        <hr />
        <S.Content>
          <S.Links>
            <ul>
              <li> Plataforma</li>
              <li> Soluções</li>
              <li> Planos</li>
              <li> Blog</li>
              <li> Parcerias</li>
              <li> Sobre nós</li>
            </ul>
          </S.Links>
          <S.Contatos>
            <ul>
              <li> Contato:</li>
              <li> Email:</li>
              <li> Siga-nos:</li>
            </ul>
          </S.Contatos>
          <S.Details>
            <ul>
              <li>+55 (11) 3254-7507</li>
              <li>
                <EmailLink email="contato@candidattus.com"  />
              </li>
              <S.Social>
                <a
                  href="https://www.facebook.com/candidattus"
                  aria-label="insta-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={FacebookIcon} alt="IG icon" />
                </a>
                <a
                  href="https://www.linkedin.com/company/candidattus/"
                  aria-label="insta-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={LinkedinIcon} alt="IG icon" />
                </a>
                <a
                  href="https://www.instagram.com/candidattus/"
                  aria-label="insta-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={InstagramIcon} alt="IG icon" />
                </a>
                <a
                  href="https://twitter.com/candidattus"
                  aria-label="insta-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={TwitterIcon} alt="IG icon" />
                </a>
              </S.Social>
            </ul>
          </S.Details>
        </S.Content>
        <hr />
        <S.Copyright>
          Tecnologia Algoricca© Software Company. ©Candidattus 2024. Todos os direitos reservados. Confira a nossa
          <button type="button" onClick={handleOpenTermsModal} className="terms-link">
             Política de Cookies{' '}
          </button>
          .{isTermsModalOpen && <TermsModal onClose={handleCloseTermsModal} />}
        </S.Copyright>

      </S.Footer>
    </S.Container>
  )
}

export default Footer
