import React, { useState } from 'react'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { noop } from 'lodash'
import type { SizeType } from "antd/es/config-provider/SizeContext";
import * as S from './styles'

interface TabsCustomProps {
  onChange?: (activeTab: string) => void
  labels: string[]
  content: React.ReactNode[]
  title?: string
}

const TabsCustom: React.FC<TabsCustomProps> = ({ labels, title, content, onChange = noop }) => {
  const [activeTab, setActiveTab] = useState('')

  const handleChange = (key: string) => {
    onChange(key)
    setActiveTab(key)
  }

  const items: TabsProps['items'] = labels.map((label, index) => {
    const key = `tab_${index + 1}`

    return {
      key,
      label: (
        <S.WrapperText >
          <S.TabText withTitle={!!title} activeTab={activeTab === key} >
            {title && (
              <>
                {title}
                <br />
              </>
            )}
            {label}
          </S.TabText>
        </S.WrapperText>
      ),
      children: <S.ContentTab>{content[index] || `Content of Tab Pane ${index + 1}`}</S.ContentTab>,
    }
  })

  return (
    <Tabs
      defaultActiveKey="1"
      items={items}
      onChange={handleChange}
      style={{ width: '100%' }}
      tabBarGutter={50}
    />
  )
}

export default TabsCustom
