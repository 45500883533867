import styled from "styled-components";

export const Container = styled.div`

  width: 100%;
  color: white;
`
export const Table = styled.table`
  width: 100%;
  color: white;
  text-align: start;

`
export const Impar = styled.tr`
  width: 100%;
  height: 28px;
  flex-shrink: 0;
  background: #1D1D1D;
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 75% */

p{
  padding: 1rem;
}
`
export const Par = styled.tr`

  width: 100%;
  height: 28px;
  background: #0A0A0A;
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 75% */

  p{
    padding: 1rem;
  }
`

export const Subtitle = styled.div`

  width: 100%;
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  padding: 1rem;
`

export const Contem = styled.div`
  width: 100%;
  color: #FFC000;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 75% */
margin-top: 1rem;
margin-bottom: 1rem;
`
