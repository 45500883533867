import React, { useState } from 'react';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';
import * as S from './styles';
import * as STable from './stylesTabela';
import { PlanoPrata,PlanoDiamante,PlanoOuro,PlanoPlatina } from "../PlansTabelas";

const Titles = ['Prata', 'Ouro', 'Platina', 'Diamante'];

const Textos = [
  'Otimize sua estratégia digital com a integração de ferramentas de análise e gestão simplificada; plano construído para autogestão ou gestão de primeira campanha.',
  'Envie mensagens personalizadas no momento certo, considerando interesses políticos e comportamento online do eleitor-alvo; plano construído para campanhas mais arrojadas, focada em grandes resultados.',
  'Direcione sua campanha com maior número de dados, ferramentas de otimização e automações aprimoradas; plano construído para partidos de até XX candidatos.',
  'Desbloqueie todos os recursos da plataforma, desde ferramentas estratégicas e de planejamento até espaços otimizados para a gestão de candidaturas; plano construído para partidos de XX mil candidatos.',
];

const tabelas = [
 <PlanoPrata/>,
  <PlanoOuro/>,
  <PlanoPlatina/>,
  <PlanoDiamante/>,
];

const PlanCard = () => {
  const [expanded, setExpanded] = useState(Array(Titles.length).fill(false));

  const toggleExpansion = (index: number) => {
    setExpanded((prevExpanded) => {
      const newExpandedState = [...prevExpanded];
      newExpandedState[index] = !newExpandedState[index];
      return newExpandedState;
    });
  };

  return (
    <div>
      {Titles.map((title, index) => (
        <S.Container key={index}>
          <S.Content>
            <S.Title>{title}</S.Title>
            <S.Text>
              {Textos[index]  ? Textos[index] : `${Textos[index].slice(0, 100)}...`}
            </S.Text>
          </S.Content>
          <S.SobConsulta>Sob Consulta</S.SobConsulta>
          <div>

            <S.Button><a href="https://meetings.hubspot.com/comercial-candidattus/agenda">Agende uma Demonstração</a></S.Button>
          </div>
          <S.Ver onClick={() => toggleExpansion(index)}>
            {expanded[index] ? 'Ver menos' : 'Ver mais'}
            {expanded[index] ? <S.Setas><SlArrowUp width={7} strokeWidth={2}/></S.Setas>
                :  <S.Setas><SlArrowDown /></S.Setas>}
          </S.Ver>
          {expanded[index] && (
            <div>
              <div>{tabelas[index]}</div>
            </div>
          )}
        </S.Container>
      ))}
    </div>
  );
};

export default PlanCard;
