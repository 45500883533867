import styled from 'styled-components'

export const Container = styled.div`
  width: 1336px;
  background: #0A0A0A;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
bottom: 0;
`;

export const Content = styled.div`
  transform: translate(110px, 0);
  width: 1200px;
  margin-top: 40px;

  .text-content{
margin-bottom: 20px;
  display: flex;
  align-self: center;
  align-items: center;
  }

`

export const Title = styled.div`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  width: 241px;
  margin-right: 30px;
`;

export const TitleTab = styled.div`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
`;
export const Subtitle = styled.div`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  width: 326px;
  margin-right: 30px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 700px;
`;
export const TextTab = styled.p`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 326px;
`;
export const Gif = styled.div`
transform: translate(-200px,0px);
  margin-bottom: 22px;
  img{
    width: 560px;
  }
`;

export const ContainerTab = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 20px;


`
export const ContentTab = styled.div`
  flex: 1;
  transform: translate(70px,10px);
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;


`
