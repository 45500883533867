import React from 'react'
import { BrowserRouter,Routes ,Route} from 'react-router-dom'
import { GlobalStyle } from 'styles/global'
import { AntProvider } from 'styles/stylesProviderAnt'
import Main from './page/Main'
import PlansPage from "./page/Planos/PlansPage";

const App = () => {
  return (
    <AntProvider>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Main/>}/>
          <Route path='/planos' element={<PlansPage/>}/>
        </Routes>
      </BrowserRouter>
    </AntProvider>
  )
}

export default App
