import styled, { css } from 'styled-components'

export const WrapperText = styled.div`

  margin-left: 0;
`

export const TabText = styled.p<{ withTitle?: boolean; activeTab?: boolean }>`
  ${({ activeTab }) => css`
    display: block;
    font-weight: ${activeTab ? '900' : '400'};
    flex: 1;
    opacity: ${activeTab ? '1' : '0,98'};
  `}
    &.ant-tabs .ant-tabs-tab:hover {
      color: #ff0000;
    }

  color: #E9E9F0;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
  width: 190px;
  height: 22px;
`

export const ContentTab = styled.div`
  flex: 1;
  color: #e9e9f0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  justify-content: center;
  margin-left: 3rem;
`
