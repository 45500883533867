import React from 'react'
import * as S from './stylesMobile'
import TabsCustomMobile from '../Tabs/indexMobile'
import tab1 from '../../assets/banners-media/candidato-preferido.gif'
import tab2 from '../../assets/banners-media/politica-vox.gif'
import tab3 from '../../assets/banners-media/arquetipo-politico.gif'
import tab4 from '../../assets/banners-media/visibilidade-de-dados.gif'
import tab5 from '../../assets/banners-media/gestao-de-campanha.gif'

import { TABS_DATA_MOBILE } from "./DATA/DATA_MOBILE";

const labels = [
  'Candidato Preferido',
  'Politica Vox®',
  'Arquétipo Político®',
  'Diagnóstico Político',
  'Portfólio de Candidaturas',
]

const contents = [
  <S.ContainerTab>
    <S.ContentTab>
      <S.Subtitle>Descubra a preferência dos eleitores com evidências sólidas</S.Subtitle>
      <S.TextTab>
        O cockpit centraliza dados sobre as características demográficas, socioeconômicas e de personalidade do
        eleitorado-alvo, monitorando suas preferências com base em pesquisas de opinião, perfil demográfico e
        outras fontes de referência.
      </S.TextTab>
    </S.ContentTab>
    <S.Gif>
      <img src={tab1} alt="Gif" />
    </S.Gif>
  </S.ContainerTab>,
  <S.ContainerTab>
    <S.ContentTab>
      <S.Subtitle>Monitore e analise os sentimentos da sua candidatura</S.Subtitle>
      <S.TextTab>
        Integra diversos recursos para capturar o pulso do eleitorado e revelar suas percepções sobre temas políticos e eleitorais em redes sociais, fóruns e outras plataformas online.
      </S.TextTab>
    </S.ContentTab>
    <S.Gif>
      <img src={tab2} alt="Gif" />
    </S.Gif>
  </S.ContainerTab>,
  <S.ContainerTab>
    <S.ContentTab>
      <S.Subtitle>Conheça as qualidades ideais do candidato com os modelos de personalidade</S.Subtitle>
      <S.TextTab>
        Esta funcionalidade identifica características do candidato ideal com modelos de personalidades políticas enriquecidas por assessments específicos e técnicas de social listening de última geração.
      </S.TextTab>
    </S.ContentTab>
    <S.Gif>
      <img src={tab3} alt="Gif" />
    </S.Gif>
  </S.ContainerTab>,
  <S.ContainerTab>
    <S.ContentTab>
      <S.Subtitle>Visualização intuitiva de padrões, tendências e exceções nos dados</S.Subtitle>
      <S.TextTab>
        O diagnóstico é feito pela coleta de dados eleitorais, como características demográficas dos eleitores e detalhes sobre candidatos e partidos, para uma identificação contextualizada do ambiente eleitoral.
      </S.TextTab>
    </S.ContentTab>
    <S.Gif>
      <img src={tab4} alt="Gif" />
    </S.Gif>
  </S.ContainerTab>,
  <S.ContainerTab>
    <S.ContentTab>
      <S.Subtitle>Compilado de dados acionáveis sobre candidatos e partidos</S.Subtitle>
      <S.TextTab>
        Fornecemos dados em tempo real que avaliam o desempenho e as estratégias de campanha de diversas entidades políticas. Isso inclui um compilado de informações sobre candidaturas e estratégias políticas adotadas pelos partidos.
      </S.TextTab>
    </S.ContentTab>
    <S.Gif>
      <img src={tab5} alt="Gif" />
    </S.Gif>
  </S.ContainerTab>
]

const BannerTabsMobile = () => {
  return (
    <>
      <S.Container>
        <S.Content>
          <div className="text-content">
            <S.Title>Descubra nossas soluções proprietárias</S.Title>
          </div>
            <S.Text>
              A plataforma Candidattus® possui uma arquitetura robusta e exclusiva que capacita os usuários a adaptarem estratégias e lidarem com crises facilmente. Explore nossas soluções proprietárias e descubra o que nos diferencia!
            </S.Text>
        </S.Content>
          <TabsCustomMobile labels={labels} content={contents} />
      </S.Container>
    </>
  )
}

export default BannerTabsMobile
