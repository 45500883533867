import styled from 'styled-components';
import BREAKPOINTS from "../../styles/deviceBreakpoints";

export const Background = styled.div`
  width: 100%;
  height: 520px;
  background-size: cover;
  color: #E9E9F0;
  display: flex;
  flex-direction: column;
  justify-content: end;



`;

export const Img = styled.img`
  display: inline-flex;
  height: 520px;
  margin-left: 6rem;
  align-items: end;
`


export const Section = styled.div`

  display: flex;
text-align: end;

`;

export const BannerContent = styled.div`
  margin-top: 7rem;
`;


export const Title = styled.h1`

  color: #FFC000;
  text-align: right;
  font-family: "Klein Condensed Trial",'sans-serif';
  font-size: 80px;
  font-style: italic;
  font-weight: 800;
  line-height: 56px; /* 70% */

`;

export const SubTitle = styled.h1`
  color: #FFF;
  text-align: right;
  font-family: "Klein Condensed Trial";
  font-size: 80px;
  font-style: italic;
  font-weight: 800;
  line-height: 48px; /* 60% */
  letter-spacing: 4px;
  text-transform: uppercase;
`;

export const LogoSlider = styled.div`
  width: 500px;
  height: 50px;
  flex-shrink: 0;

`

export const Text = styled.p`

  font-family: 'Roboto','SansSerif';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  text-align: end;
  width: 30rem;
  margin-bottom: 3rem;

`;


export const Container = styled.div`
  margin-bottom: auto;
`;



