import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 892px;
  padding: 0;
  flex-shrink: 0;
  background: #161717;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  margin-right: 14rem;
    transform: translate(0px,5px);
`

export const Title = styled.div`
  color: #E9E9F0;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
`

export const Text = styled.p`
  color: #e9e9f0;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 600px;
  transform: translateX(350px);
  /*  order: 1; */
`

export const CardConteiner = styled.div`
  display: flex;

  width: 100%;
  margin: 0 6px;
  transform: translateY(18px);

  /* Chrome, Edge and Safari */
  ::-webkit-scrollbar {
    height: 5px;
    width: 2px;

  }
  ::-webkit-scrollbar-track {
    border-radius: 16px;
    background-color: #0A0A0A;
    margin-left: 150px;
    margin-right: 120px;
  }

  ::-webkit-scrollbar-track:hover {
    background-color: #0A0A0A;
    margin-left: 150px;
    margin-right: 120px;
  }

  ::-webkit-scrollbar-track:active {
    background-color: #0A0A0A;
    margin-left: 150px;
    margin-right: 120px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: #E9E9F0;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #E9E9F0;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: #E9E9F0;
    width: 5px;
  }

`

export const Image = styled.div`
  transform: translate(-220px,70px);
  img {
    width: 1078px;
    height: 381px;
    object-fit: cover;
  }
`
